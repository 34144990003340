(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("notificationDialogController", notificationDialogController);

	function notificationDialogController($scope, $mdDialog, $sce, notifications, item, popupService, emailNavigation) {
		$scope.item = item;

		$scope.dismiss = function (event) {
			notifications.dismiss(item, event).then($mdDialog.hide, function () {});
		};

		$scope.snooze = function (minutes, event) {
			notifications.snooze(item, minutes, event).then($mdDialog.hide, function () {});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.trusted = function(html) {
			// This function helps bypass Angular's sanitize function when doing ng-bind-html.
			// The content for the dialog is sanitized by the API, and this prevents stripping the style tags.
			return $sce.trustAsHtml(html);
		}

		$scope.popoutClicked = function (event) {
			switch (item.popupAlertPopoutData.type) {
				case 1: // Email
					var packet = emailNavigation.makeEmailPacket(item.popupAlertPopoutData.accountName + '@' + item.popupAlertPopoutData.domainName,
						item.popupAlertPopoutData.folderPath.toLowerCase(),
						null,
						item.popupAlertPopoutData.mid);
					var url = emailNavigation.getPopoutUrl(packet);
					window.open(url,
						'emailID' + item.popupAlertPopoutData.mid,
						'resizable=1, ' + popupService.dimensions.email);
					break;
				case 3: // Contact
					window.open((stSiteRoot || '/') + 'interface/root#/popout/contact/' +
						item.popupAlertPopoutData.itemGuid + '/' +
						item.popupAlertPopoutData.accountName + '/' +
						item.popupAlertPopoutData.folderGuid,
							'', 'resizeable=1, ' + popupService.dimensions.contact);
					break;
				case 4: // Appointment
					if (!item.popupAlertPopoutData.occurrenceId) {
						window.open((stSiteRoot || '/') + 'interface/root#/popout/appointment/' +
							item.popupAlertPopoutData.accountName + '/' +
							item.popupAlertPopoutData.folderGuid + '/' +
							item.popupAlertPopoutData.itemGuid,
								'', 'resizeable=1, ' + popupService.dimensions.calendar);
					} else {
						window.open((stSiteRoot || '/') + 'interface/root#/popout/appointment/' +
							item.popupAlertPopoutData.accountName + '/' +
							item.popupAlertPopoutData.folderGuid + '/' +
							item.popupAlertPopoutData.itemGuid +
								'?instanceId=' + moment(item.popupAlertPopoutData.occurrenceId).utc().format('YYYYMMDDHHmmss') +
								'&instanceStart=' + moment(item.popupAlertPopoutData.start).format('YYYYMMDDHHmmss') +
								'&instanceEnd=' + moment(item.popupAlertPopoutData.end).format('YYYYMMDDHHmmss'),
									'', 'resizeable=1, ' + popupService.dimensions.calendar);
					}
					break;
				case 5: // Task
					window.open((stSiteRoot || '/') + 'interface/root#/popout/task/' + item.popupAlertPopoutData.itemGuid + '/' + item.popupAlertPopoutData.folderGuid + "/" + item.popupAlertPopoutData.accountName,
						'', 'resizeable=1, ' + popupService.dimensions.task);
					break;

			}
		};
	}
})();
