// email-actions.controller.js extends this controller and contains many functions used to take actions on emails (move, delete, block, mark, print, etc.)

(function () {
	'use strict';
	angular
		.module('smartermail')
		.controller('emailSectionController', emailSectionController);

	function emailSectionController($rootScope, $scope, $element, $http, $timeout, $q, $filter, $translate, $mdDialog, $sanitize,
		$compile, $state, $log, $localStorage, coreData, coreDataMail, $window, $controller, coreDataSettings, coreLicensing, apiCategories,
		userDataService, claimsService, messageData, authStorage, preferencesStorage, errorHandling, successHandling, treeState, simpleXmppService, popupService,
		restrictedSenders, contentFilterData, emailFunctions, themesService, emailNavigation) {
		angular.extend(this,
			$controller('emailActions',
				{
					$scope: $scope,
					$element: $element,
					$http: $http,
					$timeout: $timeout,
					$mdDialog: $mdDialog,
					coreDataMail: coreDataMail,
					coreDataSettings: coreDataSettings
				}));


		var vm = this;
		var sanitizedFolders = {};
		var firstRun = true;
		var popoutWindow;
		var debounceLoadMessage = _.debounce(loadMessage, 75);
		var debouncedWindowResize = _.debounce(onResize, 200, false);
		var refreshDisposableAddressInterval = null;

		// Properties ----------------------------

		messageData.navigationPacket = messageData.navigationPacket || {};
		$scope.branchCategories = [];
		$scope.canDeleteFolder = false;
		$scope.canEditFolder = false;
		$scope.ignoreSignalUids = [];
		$scope.inActionProgess = false;
		$scope.isDeleted = false;
		$scope.messageWindows = [];
		$scope.rootScopeBindings = [];
		$scope.searchText = ""; //coreDataMail.searchText;
		$scope.selectedBranch = undefined;
		$scope.selectedEmailUids = []; // Set to coreDataMail's list when loading mail list
		$scope.selectMode = false;
		$scope.shortTreeController = {};
		$scope.showMessagePane = false;
		$scope.totalItems = undefined;
		$scope.treeController = {};
		vm.canRunFilters = false; // Updated with watch
		vm.canUndelete = true;
		vm.contentFilters = [];
		vm.hasFullPermission = false; // Updated with watch
		vm.hasReadPermission = false; // Updated with watch
		vm.lastClickedEmail = undefined;
		vm.listLoaded = false;
		vm.markAfterPopout = false;
		vm.messageDoesNotExist = false;
		vm.runningFilters = contentFilterData.runningFilters;
		vm.searchBarVisible = false;
		vm.selectedCount = 0;
		vm.sortBy = "internalDate";
		vm.sortReverse = false;
		vm.skipNextMarkRead = true;
		vm.showManageShares = coreDataSettings.userDomainSettings.enableSharing;

		// Exported Functions --------------------
		$scope.branchMouseUp = branchMouseUp;
		$scope.closeMessagePane = closeMessagePane;
		$scope.deleteAllMessages = deleteAllMessages;
		$scope.deleteAllMessagesContext = deleteAllMessagesContext;
		$scope.deleteAllMessagesInSelectedBranch = deleteAllMessagesInSelectedBranch;
		$scope.deleteFolderClicked = deleteFolderClicked;
		$scope.deleteFolderClickedDropdown = deleteFolderClickedDropdown;
		$scope.deselectAll = deselectAll;
		$scope.disposableAddressDropdown = disposableAddressDropdown;
		$scope.editResourceDropdown = editResourceDropdown;
		$scope.formatMessageDate = coreDataMail.formatMessageDate;
		$scope.forwardClicked = onForwardClicked;
		$scope.getActiveFolder = getActiveFolder;
		$scope.getActiveFolderOwner = getActiveFolderOwner;
		$scope.getDisplayTime = getDisplayTime;
		$scope.moveFolderClicked = moveFolderClicked;
		$scope.moveFolderClickedDropdown = moveFolderClickedDropdown;
		$scope.newEmail = newEmail;
		$scope.newFolderClicked = newFolderClicked;
		$scope.newFolderClickedDropdown = newFolderClickedDropdown;
		$scope.onBranchChanged = onBranchChanged;
		$scope.onBranchOpen = onBranchOpen;
		$scope.onBranchSelect = onBranchSelect;
		$scope.openDisposableAddress = openDisposableAddress;
		$scope.openEditResourceModal = openEditResourceModal;
		$scope.openSharingModal = openSharingModal;
		$scope.popout = popout;
		$scope.purgeMarked = purgeMarked;
		$scope.refreshEmailList = refreshEmailList;
		$scope.renameFolderClicked = renameFolderClicked;
		$scope.renameFolderClickedDropdown = renameFolderClickedDropdown;
		$scope.replyClicked = onReplyClicked;
		$scope.runFilterContext = runFilterContext;
		$scope.runFilterDropdown = runFilterDropdown;
		$scope.searchClicked = searchClicked;
		$scope.selectAll = selectAll;
		$scope.setSelectMode = setSelectMode;
		$scope.treeController.addToCount = treeControllerAddToCount;
		$scope.treeExpanded = treeState.isExpanded;
		$scope.viewClicked = onViewClicked;
		$window.confirmMessageSent = confirmMessageSent;
		vm.categoryColorByName = categoryColorByName;
		vm.dragEmail = dragEmail;
		vm.nextEmail = nextEmail;
		vm.onCategoryFilterChanged = onCategoryFilterChanged;
		vm.onFilterChanged = onFilterChanged;
		vm.onSortingChanged = onSortingChanged;
		vm.onSortOrderChanged = onSortOrderChanged;
		vm.onToggleSearchBar = onToggleSearchBar;
		vm.previousEmail = previousEmail;
		vm.resetFilters = resetFilters;
		vm.setSelectMode = setSelectMode;
		vm.toggleSelectMode = toggleSelectMode;
		$scope.folderPropertiesClicked = folderPropertiesClicked;
		$scope.previousEmail = previousEmail;
		$scope.nextEmail = nextEmail;
		window.dragEmail = vm.dragEmail;
		vm.readyMenu = readyMenu;
		$scope.printMessage = printMessage;

		/////

		activate();

		// ---------------------------------------

		function activate() {
			recalculateLicense();
			coreLicensing.watchForChanges($scope, recalculateLicense);

			$scope.$on("themeService:changed", onThemeChanged);
			$scope.$on("mail.reloadSources", reloadSources);
			$scope.$on("mail.avatarLoaded", avatarLoaded);

			coreData.init()
				.then(function () {
					restrictedSenders.init().then(function () {
						init();
					}, function (failure) {
						if (failure != undefined) {
							errorHandling.report($translate.instant(failure.message || ""));
						}
					});
				}, function (failure) {
					if (failure != undefined) {
						errorHandling.report($translate.instant(failure.message || ""));
					}
				});

			$scope.tree = coreDataMail.getMailTree();
			$scope.shortTree = coreDataMail.getShortMailTree();
			$scope.lastSelectedBranchData = coreDataMail.lastSelectedBranchData;
			$scope.mailList = coreDataMail.getMailList();
			$scope.rootScopeBindings.push($rootScope.$on('mail.hideMessagePaneMobile', onHideMessagePaneMobile));
			$scope.$on('mail.messageDoesNotExist', onMessageDoesNotExist);
			window.toggleFlag = $scope.toggleFlag;

			$scope.$on("treeState:stateChange", onTreeStateChange);
			$scope.$on("categoriesUpdated", onCategoriesUpdated);
			$scope.$on("disposableAddress:changed", onDisposableAddressUpdated);
			setupRefreshMailListEvents();

			vm.mailSearchCriteria = {
				folder: "",
				ownerEmailAddress: "",
				sortType: $scope.mailList.sortType,
				sortAscending: $scope.mailList.sortAscending,
				searchFlags: undefined,
				query: coreDataMail.searchText || ""
			};

			if ($scope.mailList.filterType != undefined) {
				vm.mailSearchCriteria.searchFlags = {};
				vm.mailSearchCriteria.searchFlags[$scope.mailList.filterType] = $scope.mailList.filterValue;
			}

			// Shared button actions defined in email-actions.controller.js
			setupDragAndDrop();

			$scope.$on('resetMailList', resetMailList);
			$scope.$on("refreshEmailList", $scope.refreshEmailList);
			$scope.rootScopeBindings.push($rootScope.$on('signalR.mailHub.client.sharesChanged', onSharesChanged));

			// This needs to happen after everything else is initialized
			history.replaceState({ showMessage: $scope.showMessagePane }, null, null);
			window.addEventListener("popstate", onPopState);
			if (!claimsService.impersonating() && coreDataSettings.userSettings.seenWhatsNew) {
				var keyExist = ("email" in coreDataSettings.userSettings.seenWhatsNew);
				if (keyExist) {
					var versionOverride = localStorage.getItem("FeatureVersionOverride");
					var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["email"] : true;
					if (shouldShow) {
						var route = `~/api/v1/settings/new-features/Email${versionOverride === null ? "" : "/" + versionOverride}`;
						$http.get(route).then(onFeaturesLoaded, function () { });
					}
				} else {
					$http.get('~/api/v1/settings/new-features/Email').then(onFeaturesLoaded, function () { });
				}
			}
		}

		function init() {
			// Watch for permission changes, on any variable that can affect these permission filters
			vm.hasReadPermission = hasReadControl();
			vm.hasFullPermission = hasFullControl();
			vm.canRunFilters = allowRunFilter();
			$scope.$watch(
				'[tree.selectedBranchData.access, tree.selectedBranchData.isMappedFolder, tree.selectedBranchData.isMappedSubfolder, tree.selectedBranchData.id]',
				function () {
					vm.hasReadPermission = hasReadControl();
					vm.hasFullPermission = hasFullControl();
					vm.canRunFilters = allowRunFilter();
				}
			);

			$scope.$on("signalR.mailHub.client.contentFilterProgress", onFilterRunningUpdate);

			//$log.debug('-----init-----');
			if ($scope.treeController.deselect_all_branches && $scope.shortTreeController.deselect_all_branches) {
				$scope.treeController.deselect_all_branches();
				$scope.shortTreeController.deselect_all_branches();
			}

			window.addEventListener('resize', debouncedWindowResize);
			$timeout(function () {
				checkForAvailableMappings();

				evaluatePreviewOption();
				vm.showPurgeMarked = coreDataSettings.userSettings.deleteOption === 2 || coreDataSettings.userSettings.deleteOption === 3;
				vm.hideMailAvatars = coreDataSettings.userSettings.hideMailAvatars;
				setupResizableMailList();

				$scope.tree.selectedBranchData = preferencesStorage.getSessionSortingFilteringParam("email", "treeSelectedBranchData") || $scope.tree.selectedBranchData;
				$scope.shortTree.selectedBranchData = preferencesStorage.getSessionSortingFilteringParam("email", "shortTreeSelectedBranchData") || $scope.shortTree.selectedBranchData;
				checkSpecialFolders();
				apiCategories.categoryOwner = getActiveFolderOwner();
				//$log.debug('selected:');
				//$log.debug($scope.tree.selectedBranchData);
				//$log.debug($scope.shortTree.selectedBranchData);

				vm.disposableAddressEnabled = coreData.user.settings.features.enableDisposableAddresses;

				$scope.$on('$destroy', onDestroy);
				$scope.treeController.deselect_all_branches();
				$scope.shortTreeController.deselect_all_branches();

				const categoryFilter = apiCategories.getCategoryFilter("email");
				if (!categoryFilter.allSelected()) {
					onCategoryFilterChanged(categoryFilter.categoryData);
				}
				if (coreDataMail.reloadMailTreeOnEnter) {
					loadMailTree(true);
				} else {
					loadMailTree();
				}
				loadContentFiltering();

				refreshDisposableAddress();
				refreshDisposableAddressInterval = setInterval(refreshDisposableAddress, 15000);

				vm.isLoaded = true;
			});
		}

		function onFeaturesLoaded(result) {
			var newItems = result.data.newFeatures;
			if (newItems.length > 0) {
				$rootScope.$broadcast("user-settings:changed");
				if (newItems.length > 4 && window.innerWidth > 736) {
					$mdDialog.show({
						locals: { items: newItems },
						controller: "whatsNewDialogController",
						controllerAs: "ctrl",
						templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
						clickOutsideToClose: false
					}).then(function () { }, function () { });
				}
				else {
					$mdDialog.show({
						locals: { items: newItems },
						controller: "whatsNewDialogController",
						controllerAs: "ctrl",
						templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
						clickOutsideToClose: false
					}).then(function () { }, function () { });
				}
			}
		}

		function onDestroy() {
			for (var i = 0; i < $scope.rootScopeBindings.length; ++i) {
				$scope.rootScopeBindings[i]();
			}
			window.dragEmail = undefined;
			window.toggleFlag = undefined;
			$window.confirmMessageSent = undefined;
			coreDataMail.searchText = "";

			$(".resize-cover").remove();
			window.removeEventListener("mousemove", duringHorizResize);
			window.removeEventListener("mouseup", stopHorizResize);
			window.removeEventListener("mousemove", duringVertResize);
			window.removeEventListener("mouseup", stopVertResize);
			window.removeEventListener('resize', debouncedWindowResize);

			clearInterval(refreshDisposableAddressInterval);
		}

		function onThemeChanged() {
			setupDragAndDrop();
		}

		function setupDragAndDrop() {
			// Get current skin to determine image
			var isDark = themesService.isThemeDark();

			// Drag and drop functionality for grid and folders
			$scope.dragIcon = $scope.dragIcon || document.createElement('img');
			$scope.dragIcon.src = isDark ? 'img/dragdrop/email/multi-dark.png' : 'img/dragdrop/email/multi.png';
			$scope.dragIcon.width = 36;
			$scope.dragIcon.height = 36;

			$scope.dragIconSingle = $scope.dragIconSingle || document.createElement('img');
			$scope.dragIconSingle.src = isDark ? 'img/dragdrop/email/single-dark.png' : 'img/dragdrop/email/single.png';
			$scope.dragIconSingle.width = 36;
			$scope.dragIconSingle.height = 27;

			$scope.onDragDrop = onDragDrop;

		}

		function categoryColorByName(catName) {
			const cat = userDataService.user.categories.find(cat => cat.name === catName);
			if (!cat || cat.colorIndex == -1)
				return null;
			const color = apiCategories.getCategoryColor(cat.colorIndex);
			if (!color || !color.rgb)
				return null;
			return color.rgb;
		}

		function resetFilters() {
			$rootScope.$broadcast("doClearCategoryFilters");
			vm.onFilterChanged('all');
		}

		function onCategoryFilterChanged(categoryFilter) {
			if (categoryFilter && categoryFilter.some(cat => !cat.selected)) {
				vm.mailSearchCriteria.categoryFilter = {
					filteredCategories: categoryFilter.reduce(function (result, cat) {
						if (cat.selected && !cat.noCategory) result.push(cat.name);
						return result;
					}, []),
					includeNoCategory: categoryFilter.some(cat => cat.noCategory && cat.selected)
				};
			} else {
				vm.mailSearchCriteria.categoryFilter = null;
			}
			if (!firstRun) $scope.refreshEmailList();
		}

		function evaluatePreviewOption() {
			var initVal = vm.previewPaneOption;
			if ($rootScope.windowWidth <= 737)
				vm.previewPaneOption = "mobile";
			else if (coreDataSettings.userSettings.previewUsePreview)
				vm.previewPaneOption = "right";
			else
				vm.previewPaneOption = "none";
		}

		function onMessageDoesNotExist(ev, doesNotExist) {
			vm.messageDoesNotExist = doesNotExist;
		}

		function onHideMessagePaneMobile() {
			$scope.showMessagePane = false;
		}

		function onSharesChanged(e, args) {
			if (args && args.shareType && args.shareType != "mail") return;
			checkForAvailableMappings(true);
			reloadSources();
		}

		function recalculateLicense() {
			vm.edition = coreLicensing.edition;
		}

		function avatarLoaded(e, obj) {
			$('.st-avatar-icon').each((i, x) => {
				var email = $(x).attr('id-email');
				if (email == obj.targetEmailAddress) {
					var elemId = $(x).attr('uid');//.replace('avatar-','');
					var item = $scope.mailPanelGrid.getItemById(elemId);
					if (item) {
						if (!item.isVerifiedSender)
							return;

						item.avatarUrl = obj.avatarUrl;
						var avatarDiv = $(document.getElementById('avatar-' + item.mid));
						var avImg = avatarDiv.children('img')[0];
						var avLetter = avatarDiv.children('.letter-container')[0];
						if (!avImg)
							return;
						var ogImg = avImg.src;
						avImg.src = obj.avatarUrl;
						avImg.onload = function () {
							avLetter.style.display = "none";
							avatarDiv.removeClass(function (index, className) {
								return (className.match(/(^|\s)avatar-color-\S+/g) || []).join(' ');
							});
							avatarDiv.addClass('avatar-color-clear');
							avImg.style.display = "unset";
						};
						avImg.error = function () {
							avImg.src = ogImg;
						};
					}
				}
			});
		}

		async function loadContentFiltering() {
			try {
				const success = await $http.get('~/api/v1/settings/content-filter-groups');
				vm.contentFilters = success.data.contentFilterGroups;
			} catch (err) {
				vm.contentFilters = [];
			}
		}

		function onTreeStateChange(event, data) {
			$scope.treeExpanded = data.expanded;
		}

		function onCategoriesUpdated() {
		}

		function onDisposableAddressUpdated() {
			refreshDisposableAddress();
		}

		function onToggleSearchBar() {
			$scope.$applyAsync(function () {

				if (vm.searchBarVisible) {
					vm.searchBarVisible = false;
					if ($scope.searchText) {
						$scope.searchText = '';
						coreDataMail.searchText = $scope.searchText;
						vm.mailSearchCriteria.query = coreDataMail.searchText;
						vm.skipNextMarkRead = true;
						resetMailList();
					}
				} else {
					vm.searchBarVisible = true;
					setTimeout(function () {
						const searchBox = document.getElementById('searchBoxText');
						if (searchBox)
							searchBox.focus();
					}, 50);
				}

			});
		}

		function getActiveFolder() {
			return $scope.tree.selectedBranchData.path;
		}

		function getActiveFolderOwner() {
			return $scope.tree.selectedBranchData.owner;
		}

		function selectAll() {
			$scope.mailPanelGrid.selectAll();
		}

		function deselectAll(shouldLoadMessage) {
			$scope.mailPanelGrid.selectNone();
		}

		function onResize() {
			//This has to be delayed because sometimes when a browser is maximized/snapped/etc, the windows size values arent updated immediatly.
			$timeout(function () {
				evaluatePreviewOption();
				if (vm.selectedCount <= 1 && $rootScope.windowWidth > 737 && !messageData.messageLoaded) {
					var activeEmail = $scope.mailPanelGrid.getItemById(messageData.navigationPacket.uid);
					if (!activeEmail) { return; }
					loadMessage(activeEmail, false);
				}
			}, 50);
		}

		function buildGridItem(item, gridOptions) {
			if (!item)
				return null;
			if (!item.recipients)
				return null;
			var priority = '';
			switch (item.importance) {
				case 1:
					priority = ' priorityHigh';
					break;
				case -1:
					priority = ' priorityLow';
					break;
				default:
					priority = '';
			}
			var fromAddress = "";
			if (item.folder.toLowerCase().indexOf('sent items') == 0 || item.isDraft) {
				fromAddress = (item.recipients[0] == undefined ? "-" : (item.recipients[0].name != "" ? item.recipients[0].name : item.recipients[0].email));
			} else {
				fromAddress = (item.from ? (item.from.name != "" ? item.from.name : item.from.email) : "-");
				if (!fromAddress) {
					fromAddress = item.fromAddress || "-";
				}
			}
			var isDraggable = $scope.tree.selectedBranchData ? $scope.tree.selectedBranchData.access !== 2 : false;

			const subjEl = document.createElement('div');
			subjEl.className = 'inboxMessageSubject';
			subjEl.textContent = item.subject != undefined ? item.subject : "-";

			const fromEl = document.createElement('div');
			fromEl.className = 'inboxMessageName';
			fromEl.textContent = fromAddress;

			//THIS COMES FROM st-avatar-icon.js
			var avatarColor = 'avatar-color-clear';
			var fromName = (item.from.name != "" ? item.from.name : item.from.email) || item.fromAddress;
			var firstLetter = '';
			var nameValue = 0;
			if (fromName) {
				firstLetter = fromName.substring(0, 1).toUpperCase();
				for (var i = 0; i < fromName.length; ++i) {
					nameValue += fromName.charCodeAt(i);
				}
			}

			var shouldHideAvatar = vm.hideMailAvatars;
			if (!shouldHideAvatar && $scope.tree.selectedBranchData.untranslatedName == "SENT_ITEMS" || $scope.tree.selectedBranchData.untranslatedName == "DRAFTS")
				shouldHideAvatar = true;

			var charValue = nameValue ? (nameValue % 26) : 27;
			avatarColor = 'avatar-color-' + charValue;

			try {
				if (!shouldHideAvatar && item.avatarUrl && sessionStorage.getItem("unfound-avatar-" + encodeURIComponent(item.avatarUrl))) {
					item.avatarUrl = null;
				}
			} catch (err) { }

			const unfoundAvatarScript = item.avatarUrl
				? `try { sessionStorage.setItem('unfound-avatar-${encodeURIComponent(item.avatarUrl)}', 1); } catch (err) {}`
				: "";

			var avatarContent = shouldHideAvatar
				? ''
				: `
                    <div class="inboxAvatarContent">
                        <div class="st-avatar-icon ${item.avatarUrl ? "avatar-color-clear" : avatarColor}"
                             data-avatar-classes-error="st-avatar-icon ${avatarColor}"
                             id="avatar-${item.mid}" id-email="${item.from.email}" uid="${item.uid}">
                            <div class="letter-container" style='display: ${item.avatarUrl ? "none" : "unset"}'>${firstLetter}</div>
                            <img onerror="this.parentElement.children[0].style.display='unset'; 
                                        this.parentElement.children[1].style.display='none'; 
                                        this.parentElement.className=this.parentElement.getAttribute('data-avatar-classes-error');
                                        ${unfoundAvatarScript}" 
                                 ${item.avatarUrl ? 'src="' + item.avatarUrl + '"' : ''} 
                                 style='display: ${item.avatarUrl ? "unset" : "none"}'
                                 referrerpolicy="no-referrer">
                        </div>
                    </div>`;

			// We cache sanitized folder names because they are unlikely to change, especially within the same grid instance
			let sanitized = sanitizedFolders[item.folder];
			if (typeof sanitized === 'undefined') {
				sanitized = $sanitize(item.folder);
				sanitizedFolders[item.folder] = sanitized;
			}

			var html = `
                <div class="mailGridItem ${item.isDeleted ? 'markedAsDeleted' : ''} ${item.isFlagged ? 'markedAsFlaggedBg' : ''}"
                     data="${item.uid}|${sanitized}"
                     id="mailGridItem-${item.uid}"
                     draggable="${isDraggable}"
                     ondragstart="window.dragEmail(event)"
                     read="${item.isSeen == true}">
                    <div class="inboxMessage" oncontextmenu="return false;">
                        ${avatarContent}
                        <div class="inboxMessageContent ${priority} ${shouldHideAvatar ? '' : ' has-image'}" oncontextmenu="return false;" >
                            <div class="messageRow inboxMessageFirstLine">
                                ${fromEl.outerHTML}
                                <div class="inboxMessageDate">
                                    ${$scope.formatMessageDate($scope.getDisplayTime(item.internalDate), true)}
                                </div>
                            </div>
                            <div class="messageRow">
                                ${subjEl.outerHTML}
                                <div class="empty"></div>
                            </div>
                            <div class="messageRow inboxMessageLastLine">
                                <div class="inboxMessageSize">${$filter('bytes')(item.size)}</div>
                                <div class="category-stack">${$scope.getCategoryStackHtml(item.categories)}</div>
                                <div class="inboxMessageIcons">${getMessageIcons(item)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            `;
			return html;

			function getMessageIcons(item) {
				var html = '';

				if (item.importance == 1) {
					html += "<i class='toolsicon toolsicon-priority_high'></i>";
				}
				if (item.isReplied) {
					html += "<i class='toolsicon toolsicon-reply'></i>";
				}
				else if (item.isForwarded) {
					// Forwarded icon only shows if reply does not
					html += "<i class='toolsicon toolsicon-forward'></i>";
				}
				if (item.isCalendarMessage) {
					html += "<i class='toolsicon toolsicon-calendar'></i>";
				}
				if (item.hasAttachedTask) {
					html += "<i class='toolsicon toolsicon-check'></i>";
				}
				if (item.hasAttachments) {
					html += "<i class='toolsicon toolsicon-attach_file'></i>";
				}
				if (item.isDraft) {
					html += "<i class='toolsicon toolsicon-file-edit-outline'></i>";
				}
				if (item.encryptSignFlags) {
					if (item.encryptSignFlags.isEncrypted) {
						html += "<i class='toolsicon toolsicon-encrypted'></i>";
					}
					if (item.encryptSignFlags.isSigned) {
						if (item.encryptSignFlags.passedVerification) {
							html += "<i class='toolsicon toolsicon-fingerprint'></i>";
						}
					}
				}

				// Readonly folders cannot change flag status
				var readOnly = $scope.tree.selectedBranchData && $scope.tree.selectedBranchData.access === 2;
				if (readOnly) {
					if (item.isFlagged)
						html += `<i class="toolsicon toolsicon-flag"></i>`;
					// No icon is shown on readonly folders that are not flagged
				}
				else {
					html += item.isFlagged
						? `<i class="toolsicon toolsicon-flag" onclick="window.toggleFlag(${item.uid}, true)"></i>`
						: `<i class="toolsicon toolsicon-flag messageNotFlagged" onclick="window.toggleFlag(${item.uid}, false)"></i>`;
				}

				return html;
			}
		}

		function clickGridItem(index, item, event) {
			if (item) {
				vm.canUndelete = item.isDeleted || (vm.isInDeleted && vm.selectedCount > 0);
				$scope.isDeleted = item.isDeleted || false;
			}
			/*
			 *	Mark Read and Preview Logic:
			 *		When a messages is loaded in the preview pane by a user directed action, it will be marked as read.
			 *		User directed actions require an event such as left click OR arrow navigation actiona.
			 *		A load message may occurr when a message is auto-selected (after a change folder, delete or move action)
			 *			auto-selected does not change the read state.
			 *		Since mark read occurrs after message load [message.controller.processMessage -> email-actions.controller.viewAsClicked],
			 *			add the uid to markAsReadQueue in the messageData service 
			 */
			if (item && item.size &&  // [item.size check is to just check that the item has more than just the uid.]
				(vm.previewPaneOption != "none" || $rootScope.windowWidth < 737)) {
				if (event && (event.type === 'click' || event.type === 'keydown') && (!item.isSeen ? true : false)) {
					messageData.markAsReadQueue.push(item.uid);
				}
				debounceLoadMessage(item);
				$scope.showMessagePane = !firstRun;
				history.pushState({ showMessage: $scope.showMessagePane }, null, null);
				if (firstRun)
					firstRun = false;
			}
			if ((popoutWindow && popoutWindow.closed) || (!popoutWindow && !firstRun)) {
				$('#MailPanelGrid').trigger("focus");
			}
		}

		function doubleClickGridItem(id, item, event) {
			$scope.popout(item.uid, !item.isSeen);
		}

		function onSelectionCountChanged(count) {
			$scope.$broadcast("mail.selectionCountChanged", count);
			$scope.$applyAsync(function () {
				vm.selectedCount = count;
				if (count > 1) {
					unloadMessage();
				}
				if (count > 1 || vm.previewPaneOption === "none" || $rootScope.windowWidth < 737) {
					messageData.refreshSelection($scope.getSelectedItems());
				}
			});
		}

		function onGetDataCompleted(data) {
			$scope.totalItems = data;
			vm.listLoaded = true;
			if (!$scope.totalItems || $scope.totalItems == 0) {
				vm.selectedCount = 0;
				vm.lastClickedEmail = undefined;
				unloadMessage();
			}
			$rootScope.$broadcast('mail.mailListLoaded');
			$scope.$applyAsync();
			$rootScope.spinner.hide();
			if ((popoutWindow && popoutWindow.closed) || !popoutWindow && !firstRun) {
				$('#MailPanelGrid').trigger("focus");
			}
		}

		function onDeleteKey() {
			$scope.deleteClicked();
		}

		async function contextMenuGridItem(items, event) {
			if (!items || items.length == 0)
				return;

			// user right clicked on item that may not yet be loaded
			if (items.length === 1)
				messageData.refreshSelection($scope.getSelectedItems());

			if (!(event && (event.type === 'touchstart' || event.type === 'touchend' || event.which === 3)))
				return;

			event.stopPropagation();
			event.preventDefault();

			$scope.dropdownEvent = $.extend(true, {}, event);
			$scope.dropdownOptions = await getDropdownMenuOptions();

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		async function readyMenu(event, $mdOpenMenu) {
			var newOptions = await getDropdownMenuOptions();
			$scope.$applyAsync(function () {
				$scope.dropdownOptions = newOptions;
				$timeout(function () { $mdOpenMenu(event); }, 0);
			});
		}

		async function getDropdownMenuOptions() {
			var uids = $scope.getSelectedUids();
			var menu = [];
			var items = $scope.getSelectedItems();
			const writeAccess = hasFullControl();
			const readAccess = hasReadControl();
			const isDraft = messageData.selection.isDraft;
			const hasUnread = messageData.selection.hasUnseen;
			const hasRead = messageData.selection.hasSeen;
			const hasUnflagged = messageData.selection.hasUnflagged;
			const hasFlagged = messageData.selection.hasFlagged;
			const isSharedFolder = $scope.tree.selectedBranchData.access != undefined && $scope.tree.selectedBranchData.access != 0;

			if (writeAccess) {
				menu.push({ key: "replyClicked", translateKey: "REPLY", click: $scope.replyClicked, params: 1, disabled: isDraft || items.length != 1 });
				menu.push({ key: "replyAllClicked", translateKey: "REPLYALL", click: $scope.replyClicked, params: 2, disabled: isDraft || items.length != 1 });
				menu.push({ key: "forwardClicked", translateKey: "FORWARD", click: $scope.forwardClicked, params: 3, disabled: isDraft || items.length == 0 });
				menu.push({ key: "moveClicked", translateKey: "MOVE", click: $scope.moveClicked, params: uids, disabled: isDraft || items.length == 0 });
				menu.push({ divider: true });
			}

			if (writeAccess) {
				if (hasUnflagged || !hasFlagged)
					menu.push({ key: 'markMessageFlag', translateKey: 'MARK_FOLLOWUP', click: $scope.markMessageFlagDropdown, params: { type: 'SetBasic', uid: uids }, disabled: items.length == 0 || !hasUnflagged });
				if (hasFlagged)
					menu.push({ key: 'markMessageUnflagged', translateKey: 'MARK_NOFOLLOWUP', click: $scope.markMessageFlagDropdown, params: { type: 'Clear', uid: uids } });
			}

			if (readAccess) {
				if (hasUnread || !hasRead)
					menu.push({ key: 'markMessageRead', translateKey: 'MARK_READ', click: $scope.markMessageDropdown, params: { type: 'read', uid: uids }, disabled: items.length == 0 });
				if (hasRead)
					menu.push({ key: 'markMessageUnread', translateKey: 'MARK_UNREAD', click: $scope.markMessageDropdown, params: { type: 'unread', uid: uids } });
			}

			if (writeAccess && !isDraft && !vm.isInSentItems && !isSharedFolder) {
				if (vm.isInJunk)
					menu.push({ key: 'moveToInbox', translateKey: 'MOVE_TO_INBOX', click: $scope.spamClicked, params: false, disabled: items.length == 0 });
				else
					menu.push({ key: 'moveToJunk', translateKey: 'MOVE_TO_JUNK_SHORT', click: $scope.spamClicked, params: true, disabled: items.length == 0 });
			}

			if (writeAccess && !isDraft && !vm.isInSentItems && items.length > 0) {
				try {
					var result = await restrictedSenders.isTrustedSender(items[0].fromAddress);
					const isTrustedSender = result.isTrusted;
					const trustedFromSource = result.trustSource;

					// Trust/untrust sender
					const isTrustDisabled = trustedFromSource != "USER" && trustedFromSource != "NONE" && items.length == 1;
					menu.push({ divider: true });
					if (!isTrustedSender || items.length > 1)
						menu.push({ key: 'trustSender', click: $scope.trustSender, params: uids, translateKey: 'TRUST_SENDER', disabled: isTrustDisabled });
					if (isTrustedSender || items.length > 1)
						menu.push({ key: 'untrustSender', click: $scope.untrustSender, params: uids, translateKey: 'UNTRUST_SENDER', disabled: isTrustDisabled });
				} catch (err) { }
			}

			if (writeAccess && !isDraft && !vm.isInSentItems && items.length > 0) {
				const isBlockedSender = restrictedSenders.isBlockedSender(items[0].fromAddress);
				const isSenderInGAL = restrictedSenders.isSenderInGAL(items[0].fromAddress);

				// Block/unblock sender
				var isBlockDisabled = items[0].fromAddress == coreData.user.emailAddress || isSenderInGAL;
				if (!isBlockedSender || items.length > 1)
					menu.push({ key: 'blockSender', click: $scope.blockSenderDropdown, params: { uid: uids, ev: $scope.dropdownEvent }, translateKey: 'BLOCK_SENDER', disabled: isBlockDisabled && items.length == 1 });
				if (isBlockedSender || items.length > 1)
					menu.push({ key: 'unblockSender', click: $scope.unblockSender, params: uids, translateKey: 'UNBLOCK_SENDER' });

				// Create/Add to filter
				//    menu.push(
				//        { key: 'createFilter', click: $scope.createFilter, params: uids, translateKey: 'CREATE_FILTER', disabled: items.length != 1 }
				//    );
				//    menu.push(
				//        { key: 'addToFilter', click: $scope.addToFilter, params: uids, translateKey: 'ADD_SENDER_TO_FILTER' }
				//    );
			}

			if (readAccess) {
				menu.push({ divider: true });
				menu.push({ key: "downloadRaw", translateKey: "DOWNLOAD_EML", click: $scope.onDownloadRaw, params: uids, disabled: items.length == 0 });
				menu.push({ key: "viewRaw", translateKey: "VIEW_RAW_CONTENT", click: onViewClicked, params: 'raw', disabled: items.length != 1 });
			}

			if (writeAccess) {
				menu.push({ divider: true });

				const canDelete = !$scope.isDeleted || vm.selectedCount > 1;
				menu.push({ key: 'delete', translateKey: 'DELETE', click: $scope.deleteClickedDropdown, params: { uid: uids }, disabled: !canDelete });

				const folderHasUndeleteSupport = $scope.tree.selectedBranchData.deleteBehavior === 2 || $scope.tree.selectedBranchData.deleteBehavior === 3;
				const canUndelete = vm.canUndelete || vm.selectedCount > 1;
				if (folderHasUndeleteSupport)
					menu.push({ key: 'unDelete', translateKey: 'UNDELETE', click: $scope.undeleteClicked, params: uids, disabled: canUndelete });

				if (vm.showPurgeMarked)
					menu.push({ key: 'purgeMarked', translateKey: 'MAIL_PURGE_MARKED_DELETE', click: $scope.purgeMarked, params: uids });

				menu.push({ key: 'deleteAll', translateKey: 'DELETE_ALL_IN_FOLDER', click: $scope.deleteAllMessagesInSelectedBranch });
			}

			return menu;
		}

		async function onViewClicked(mode) {
			var newWindow;
			if (mode == 'raw' && !messageData.message.raw) {
				if ($scope.messageWindows[messageData.message.uid] && $scope.messageWindows[messageData.message.uid].closed)
					$scope.messageWindows[messageData.message.uid] = undefined;
				if (!$scope.messageWindows[messageData.message.uid]) {
					newWindow = window.open(coreData.baseUrl + '/interface/view-message',
						messageData.message.uid,
						'resizable=1, ' + popupService.dimensions.email);
					$scope.messageWindows[messageData.message.uid] = newWindow;
					$scope.messageWindows[messageData.message.uid].unload = function () {
						$scope.messageWindows[messageData.message.uid] = undefined;
					};
				} else {
					newWindow = $scope.messageWindows[messageData.message.uid];
				}

				try {
					var parameters = {
						'Folder': messageData.message.folder,
						'UID': messageData.message.uid,
						'OwnerEmailAddress': messageData.message.ownerEmailAddress != undefined ? messageData.message.ownerEmailAddress : ""
					};
					const response = await $http.post("~/api/v1/mail/message/raw", parameters);
					if (messageData.message.uid == parameters.UID) {
						messageData.message.raw = response.data;
						popoutSimpleMessageView(mode, newWindow);
					}
				} catch (err) {
					// Ignored. It just won't change to raw content if it fails
				}
			} else {
				popoutSimpleMessageView(mode);
			}
		}

		function popoutSimpleMessageView(mode, newWindow) {
			if (!newWindow && $scope.messageWindows[messageData.message.uid] && $scope.messageWindows[messageData.message.uid].closed)
				$scope.messageWindows[messageData.message.uid] = undefined;
			if (!$scope.messageWindows[messageData.message.uid]) {
				newWindow = window.open(coreData.baseUrl + '/interface/view-message',
					messageData.message.uid,
					'resizable=1, ' + popupService.dimensions.email);
				$scope.messageWindows[messageData.message.uid] = newWindow;
				$scope.messageWindows[messageData.message.uid].unload = function () {
					$scope.messageWindows[messageData.message.uid] = undefined;
				};
			} else if (!newWindow) {
				newWindow = $scope.messageWindows[messageData.message.uid];
			}

			$timeout(function () {
				checkSetHtml(0);
			});

			function checkSetHtml(depth) {
				if (depth > 25) { return; }
				if (newWindow && newWindow.setHTML) {
					newWindow.setHTML(messageData.message, mode);
					newWindow.focus();
				} else {
					$timeout(function () { checkSetHtml(++depth); }, 50);
				}
			}
		}

		async function getAccessToken(toCall, data, callback, idOnly) {
			try {
				const success = await authStorage.getTokenWithPromise();
				data.apiAccessToken = success;
				toCall(data);
			} catch (err) {
				errorHandling.report(err);
			}
		}
		// this function registers the signalR mail "mailListRemoved", "mailListAdded", "mailListModified" notifications and will delay the refresh to every 500ms 
		//   when a draft message is saved from popout or external client it will generate all 3 notifications 
		//	 we need to distinguish a modification from a draft save so we do not try to load a deleted 
		function setupRefreshMailListEvents() {
			const pendingChanges = {};
			let locked = false;
			const debouncedRefresh = _.debounce(function () {
				locked = true;
				if (Object.keys(pendingChanges).length) {
					let gridSelectionFunc = undefined;

					Object.keys(pendingChanges).forEach(
						change => {
							const mid = parseInt(change);
							if (messageData.message && mid === messageData.message.mid) {
								if (pendingChanges[change].added) {
									let packet = emailNavigation.makeEmailPacket($scope.tree.selectedBranchData.owner, $scope.tree.selectedBranchData.path, pendingChanges[change].added, mid, { softReload: true });
									$scope.$broadcast("loadMessage", packet);
								}
								else if (pendingChanges[change].mod && !pendingChanges[change].removed) {
									let packet = emailNavigation.makeEmailPacket($scope.tree.selectedBranchData.owner, $scope.tree.selectedBranchData.path, pendingChanges[change].mod, mid, { softReload: true });
									$scope.$broadcast("loadMessage", packet);
								}
								else if (pendingChanges[change].removed) {
									gridSelectionFunc = (panelRef, index) => {
										$timeout(() => {
											panelRef.focusedIndex = index;
											panelRef.focusItem();

											panelRef.selectItemByIndex(index, {});
										}, 100);
									}
								}
							}
							delete pendingChanges[change];
						}
					)
					$scope.mailPanelGrid.reset({ }, gridSelectionFunc);
					$scope.$applyAsync();
				}
				locked = false;
			}, 1000);
			function onMailListAdded(event, data) {
				let addedSet = [];
				for (var i = 0; i < data.length; ++i) {
					var v = data[i];
					if (v.folder.toUpperCase() === vm.mailSearchCriteria.folder.toUpperCase() &&
						(v.ownerEmailAddress.toUpperCase() === vm.mailSearchCriteria.ownerEmailAddress.toUpperCase() ||
							v.ownerEmailAddress === "" && coreData.user.emailAddress)) {
						addedSet.push(v);
					}
				}
				const submitAdds = () => {
					if (addedSet.length) {
						addedSet.forEach(v => {
							pendingChanges[v.mid] = pendingChanges[v.mid] || {};
							pendingChanges[v.mid].added = v.uid;
						});
						debouncedRefresh();
					}
				}
				if (locked) {
					$timeout(submitAdds, 50);
				} else {
					submitAdds();
				}
			}
			function onMailListRemoved(event, data) {
				var removalSet = [];
				if ($scope.ignoreSignalUids.indexOf(-1) !== -1) {
					$scope.ignoreSignalUids = [];
					return;
				}
				if ($localStorage.ignoreMailSignal === true) {
					$localStorage.ignoreMailSignal = undefined;
					return;
				}
				var ignoreFolder = "";
				if ($localStorage.ignoreMailSignal && typeof $localStorage.ignoreMailSignal === "string") {
					ignoreFolder = $localStorage.ignoreMailSignal.toLowerCase();
					$localStorage.ignoreMailSignal = undefined;
				}
				for (let i = 0; i < data.length; ++i) {
					var v = data[i];
					if ($scope.ignoreSignalUids.indexOf(v.uid) !== -1) {
						$scope.ignoreSignalUids = _.without($scope.ignoreSignalUids, v.uid);
						continue;
					}
					if (v.folder.toLowerCase() === vm.mailSearchCriteria.folder.toLowerCase() &&
						(v.ownerEmailAddress === vm.mailSearchCriteria.ownerEmailAddress ||
							v.ownerEmailAddress === "" && coreData.user.emailAddress) &&
						v.folder.toLowerCase() !== ignoreFolder) {
						removalSet.push(v);
					}
				}
				var uids = [];
				for (let i = 0; i < removalSet.length; ++i) {
					uids.push(removalSet[i].uid);
				}
				preferencesStorage.setLocalParam("deletedUids", uids);
				const submitRemovals = () => {
					if (removalSet.length) {
						removalSet.forEach(v => {
							pendingChanges[v.mid] = pendingChanges[v.mid] || {};
							pendingChanges[v.mid].removed = v.uid;
						});
						debouncedRefresh();
					}
				}
				if (locked) {
					$timeout(submitRemovals, 50);
				} else {
					submitRemovals();
				}

			}
			function onMailListModified(event, data) {
				if ($localStorage.ignoreMailSignal) {
					return;
				}
				let modSet = [];
				for (var i = 0; i < data.length; ++i) {
					var v = data[i];
					if ($scope.ignoreSignalUids.indexOf(v.uid) !== -1) {
						$scope.ignoreSignalUids = _.without($scope.ignoreSignalUids, v.uid);
						continue;
					}
					if (v.folder.toLowerCase() === vm.mailSearchCriteria.folder.toLowerCase() &&
						(v.ownerEmailAddress === vm.mailSearchCriteria.ownerEmailAddress ||
							v.ownerEmailAddress === "" && coreData.user.emailAddress)) {
						modSet.push(v);

					}
				}
				const submitModSet = () => {
					if (modSet.length) {
						modSet.forEach(v => {
							pendingChanges[v.mid] = pendingChanges[v.mid] || {};
							pendingChanges[v.mid].mod = v.uid;
						});
						debouncedRefresh();
					}
				}
				if (locked) {
					$timeout(submitModSet, 50);
				} else {
					submitModSet();
				}
			}
			$scope.$on("mailListRemoved", onMailListRemoved);
			$scope.$on("mailListAdded", onMailListAdded);
			$scope.$on("mailListModified", onMailListModified);

		}


		function branchMouseUp(branch, event) {
			event.stopPropagation();
			event.preventDefault();
			event.cancelbubble = true;
			$scope.dropdownOptions = [];
			if (event && event.which === 3) {
				$scope.dropdownEvent = $.extend(true, {}, event);
				if (branch.data.unclickable) {
					// Unclickable items have no children
				}
				else if ((!branch.data.isShared || branch.data.isBeingShared)) {
					$scope.dropdownOptions = [
						{ key: 'newFolder', click: $scope.newFolderClickedDropdown, params: { branch: branch, event: event, isRootFolder: false }, translateKey: 'NEW_FOLDER' },
					];
					if (branch.data.canEdit && branch.data.access !== 2) {
						$scope.dropdownOptions = $scope.dropdownOptions.concat([
							{ key: 'moveFolder', click: $scope.moveFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: 'MOVE_FOLDER' },
							{ key: 'renameFolder', click: $scope.renameFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: 'EDIT_FOLDER' },
							{ key: 'deleteFolder', click: $scope.deleteFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: 'DELETE_FOLDER' }
						]);

					}
					if (branch.data.id != "Drafts" && visibilityCheckShareFolder(branch)) {
						$scope.dropdownOptions = $scope.dropdownOptions.concat([
							{ key: 'mapResource', click: $scope.editResourceDropdown, params: { branch: branch, event: event }, translateKey: 'SHARE_FOLDER' }
						]);
					}

					$scope.dropdownOptions = $scope.dropdownOptions.concat([
						{ divider: true },
					]);

					if (vm.disposableAddressEnabled || vm.canRunFilters) {
						if (vm.canRunFilters) {
							$scope.dropdownOptions.push({
								key: 'runContentFilter',
								translateKey: 'RUN_CONTENT_FILTER',
								click: runFilterContext,
								params: { branch: branch, event: event },
								disabled: vm.runningFilters || vm.contentFilters.length === 0
							});
						}
						if (vm.disposableAddressEnabled) {
							$scope.dropdownOptions.push({
								key: 'openDisposableAddress',
								translateKey: 'DISPOSABLE_ADDRESS',
								click: disposableAddressDropdown,
								params: { branch: branch, event: event },
							});
						}
					}

					if (branch.data.totalMessages > 0 && branch.data.access !== 2) { // not read-only and contains messages
						$scope.dropdownOptions = $scope.dropdownOptions.concat([
							{ divider: true },
						]);
						$scope.dropdownOptions = $scope.dropdownOptions.concat([
							{ key: 'deleteAllMessagesContext', disabled: branch.data.totalMessages === 0, click: $scope.deleteAllMessagesContext, params: { branchData: branch.data, event: event }, translateKey: 'DELETE_ALL_IN_FOLDER' }
						]);
					}

				} else if (branch.data.isShared) {
					$scope.dropdownOptions = [];

					//if (branch.data.access >= 3) {
					$scope.dropdownOptions.push({ key: 'newFolder', click: $scope.newFolderClickedDropdown, params: { branch: branch, event: event, isRootFolder: false }, translateKey: 'NEW_FOLDER' });

					// Only enable if it is a default folder
					//    if (branch.data.canEdit) {
					//        $scope.dropdownOptions.push({ key: 'renameFolder', click: $scope.renameFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: 'EDIT_FOLDER' });
					//    }

					//    $scope.dropdownOptions.push({ key: 'deleteFolder', click: $scope.deleteFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: 'DELETE_FOLDER' });
					//}

					$scope.dropdownOptions.push({ key: 'editMapFolder', click: $scope.editResourceDropdown, params: { branch: branch, event: event, isRootFolder: false }, translateKey: "PROPERTIES" });
				}

				if ($scope.dropdownOptions.length === 0) return;

				var elementToCompile = '<st-context-menu options="::dropdownOptions" event="::dropdownEvent" classes="::[\'dropdown-no-scroll\']" menu-like="::true" menu-class="::\'abn-tree-row\'"></st-context-menu>';
				var element = $('#context-menu-area');
				if (element) {
					var elementCompiled = $compile(elementToCompile)($scope);
					element.append(elementCompiled);
				}
			}
		}

		function toggleSelectMode() {
			if ($scope.selectMode) {
				setSelectMode(false, true);
			}
			else {
				setSelectMode(true);
			}
		}

		function setSelectMode(mode, deselectAll) {
			$scope.selectMode = mode;
			$scope.mailPanelGrid.setSelectMode(mode);
			if (!$scope.selectMode && deselectAll) {
				$scope.deselectAll();
			}
		}

		function onBranchOpen(branch, event) {
			var expanded = preferencesStorage.getSortingFilteringParam('email', 'expandedBranches');
			if (!expanded) { expanded = []; }
			if (branch.expanded) {

				if (expanded.findIndex(e => e.toLowerCase() === branch.data.key.toLowerCase()) === -1) {
					expanded.push(branch.data.key);
				}
			} else {
				var index = expanded.findIndex(e => e.toLowerCase() === branch.data.key.toLowerCase());
				expanded.splice(index, 1);
			}
			preferencesStorage.setSortingFilteringParam('email', 'expandedBranches', expanded);
		}

		function loadMessage(mail) {
			if (vm.markAfterPopout) {
				vm.markAfterPopout = false;
			}
			if (mail.uid < 0) { return; }

			if (messageData.navigationPacket.uid != mail.uid) {
				let packet = emailNavigation.makeEmailPacket(mail.ownerEmailAddress, mail.folder.toLowerCase(), mail.uid);
				$scope.$broadcast("loadMessage", packet);
				$timeout(function () {
					$('#mailContentView').scrollTop(0);
				});
			}
			else {
				let packet = emailNavigation.makeEmailPacket(mail.ownerEmailAddress, mail.folder.toLowerCase(), mail.uid, undefined, { softReload: true });
				$scope.$broadcast("loadMessage", packet);

				// If the message is already loaded in preview, we still need to make sure
				// the message is marked as read
				if (!mail.isSeen && !vm.skipNextMarkRead)
					$scope.markMessage("read", mail.uid);
			}

			vm.skipNextMarkRead = false;
		}

		// Shared button actions defined in email-actions.controller.js

		function confirmMessageSent(id, mode) {
			$timeout(function () {
				successHandling.report('MESSAGE_SENT');
				var m = $scope.mailPanelGrid.getItemById(id);
				if (m != undefined) {
					if (mode == 1)
						m.isReplied = true;
					else if (mode == 2)
						m.isForwarded = true;
				}
			});
		}

		//#region button bar functions
		function onReplyClicked(mode) {
			var items = $scope.mailPanelGrid.getSelectedItems();
			if (items.length === 1) {
				var listEmail = items[0];
				if (listEmail && !listEmail.isSeen) {
					$scope.markMessage('read', items[0].uid);
				}
				popoutWindow = emailFunctions.popupReplyWindow($scope.tree.selectedBranchData.owner, $scope.tree.selectedBranchData.path, items[0].uid, mode);
			}
		}


		async function onForwardClicked(mode) {
			var items = $scope.mailPanelGrid.getSelectedItems();
			if (items.length === 1 && coreData.user.settings.userMailSettings.forwardMethod !== 'attachment') {
				let listEmail = items[0];
				if (listEmail && listEmail.isSeen != undefined && !listEmail.isSeen) {
					$scope.markMessage('read', listEmail.uid);
				}
				popoutWindow = emailFunctions.popupReplyWindow($scope.tree.selectedBranchData.owner, $scope.tree.selectedBranchData.path, listEmail.uid, mode);
			} else {
				try {
					$rootScope.spinner.show();

					var fwdEmails = [];
					var promises = [];
					for (let i = 0; i < items.length; ++i) {
						let listEmail = items[i];
						if (listEmail.size) {
							fwdEmails.push({ uid: listEmail.uid, ownerEmailAddress: $scope.tree.selectedBranchData.owner, folder: $scope.tree.selectedBranchData.path, size: listEmail.size });
							promises.push($http.post("~/api/v1/mail/message", { Folder: $scope.tree.selectedBranchData.path, UID: listEmail.uid, OwnerEmailAddress: $scope.tree.selectedBranchData.owner }));
						}
					}

					const success = await $q.all(promises);
					for (let i = 0; i < success.length; ++i) {
						fwdEmails[i].subject = success[i].data.messageData.subject ? success[i].data.messageData.subject : '';
					}

					const packet = emailNavigation.makeComposePacket({
						owner: $scope.tree.selectedBranchData.owner,
						folder: $scope.tree.selectedBranchData.path,
						uid: items[0].uid,
						reply: mode,
						attachEmails: fwdEmails
					});
					const url = emailNavigation.getPopoutComposeUrl(packet);
					popoutWindow = window.open(url, "", 'resizable=1, ' + popupService.dimensions.email);
				}
				catch (err) {
					errorHandling.report(err);
				}
				finally {
					$rootScope.spinner.hide();
				}
			}
		}
		//#endregion

		function dragEmail(event) {
			var data = $(event.target).attr('data').split('|');
			var uid = parseInt(data[0], 10);
			var selectedUids = $scope.getSelectedUids();
			var dragSelected = (selectedUids.indexOf(Number(uid)) > -1);

			//Microsoft is ignoring the standard right now and doesn't allow custom mime types in setData.
			//https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8007622/
			//event.dataTransfer.setData("uid", uid);
			//event.dataTransfer.setData("folder", data[1]);
			//event.dataTransfer.setData("owner", $scope.tree.selectedBranchData.owner);
			var hasPermission = $scope.tree.selectedBranchData.access !== 2;
			//event.dataTransfer.setData("hasPermission", hasPermission);
			var transferData = { uid: uid, selected: selectedUids, dragSelected: dragSelected, folder: data[1], owner: $scope.tree.selectedBranchData.owner, hasPermission: hasPermission };
			//I would like to at least use application/json here. but ie only supports text and url.
			event.dataTransfer.setData("text", JSON.stringify(transferData));

			if (hasPermission) {
				event.dataTransfer.dropEffect = 'move';
				event.dataTransfer.effectAllowed = 'all';
			} else {
				event.dataTransfer.dropEffect = 'none';
				event.dataTransfer.effectAllowed = 'none';
			}

			//Guess what. Micrsoft doesn't support setDragImage either! Isn't Edge just the best modern browser!
			//https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/329507/
			if (vm.selectedCount > 1 && event.dataTransfer.setDragImage) {
				event.dataTransfer.setDragImage($scope.dragIcon, -18, -10);
			} else if (event.dataTransfer.setDragImage) {
				event.dataTransfer.setDragImage($scope.dragIconSingle, -18, -10);
			}
		}

		function onDragDrop(event) {
			var transferData = JSON.parse(event.dataTransfer.getData("text"));
			if (transferData.folder == "" || transferData.hasPermission == false)
				return;

			var uids = transferData.dragSelected ? transferData.selected : [transferData.uid];
			var folderPaths = event.currentTarget.attributes.key.value.split("|");
			var destinationOwner = folderPaths[0];
			var destinationFolder = folderPaths[1];
			if (destinationFolder == '')
				return;

			$scope.moveEmails(uids,
				transferData.folder,
				transferData.owner,
				destinationFolder,
				destinationOwner);

			event.stopPropagation();
		}

		function onBranchSelect(branch, treeType) {
			$scope.selectedBranch = branch;
			if (treeType == 'mail_short') {
				$scope.treeController.deselect_all_branches();
			} else {
				$scope.shortTreeController.deselect_all_branches();
			}
		}

		/* Folder Tree Functions */
		function newFolderClickedDropdown(params) {
			$scope.newFolderClicked(params.event, params.isRootFolder, params.branch);
		}

		function newFolderClicked(ev, isRootFolder, branch) {
			if ((branch && branch.data && branch.data.access === 3) ||
				($scope.tree.selectedBranchData && $scope.tree.selectedBranchData.access === 3)) {
				var currentBranchData = (branch && branch.data) ? branch.data : $scope.tree.selectedBranchData;
				coreDataMail.getSharedFolderList(currentBranchData.owner)
					.then(function (success) {
						$mdDialog.show({
							controller: "createFolderController",
							templateUrl: "app/email/modals/create-folder.dlg.html",
							targetEvent: ev,
							locals: { folders: success, parentFolder: currentBranchData, owner: currentBranchData.owner },
							clickOutsideToClose: false
						})
							.then(function (params) {
								if (params.success) {
									var parentFolder = params.folder.path.split("/");
									if (parentFolder.length > 1) {
										parentFolder.splice(parentFolder.length - 1, 1);
										parentFolder = parentFolder.join("/");
									} else {
										parentFolder = "";
									}
									parentFolder = parentFolder.toLowerCase();
									var newBranch = {
										label: params.name,
										data: {
											"mid": params.folder.id,
											"id": params.name,
											"label": params.name,
											"count": 0,
											"path": params.folder.path.toLowerCase(),
											"canDelete": true,
											"canEdit": true,
											"access": 3,
											"key": currentBranchData.owner + "|" + params.folder.path.toLowerCase(),
											"owner": currentBranchData.owner,
											"isMappedFolder": false,
											"isMappedSubfolder": currentBranchData.isMappedFolder || currentBranchData.isMappedSubfolder,
											"isShared": currentBranchData.isMappedFolder || currentBranchData.isMappedSubfolder,
										}
									};
									var isShort = false;
									var parentBranch = $scope.tree.map[currentBranchData.owner + "|" + parentFolder];
									if (!parentBranch && parentFolder !== "") {
										parentBranch =
											$scope.shortTree.map[currentBranchData.owner + "|" + parentFolder];
										isShort = true;
									}
									if (parentBranch != undefined) {
										if (!isShort) {
											$scope.treeController.add_branch(parentBranch, newBranch);
										} else {
											$scope.shortTreeController.add_branch(parentBranch, newBranch);
										}
										//expand folder
										var expandedBranches =
											preferencesStorage.getSortingFilteringParam("email", "expandedBranches") ||
											[];
										var index = expandedBranches.findIndex(e => e.toLowerCase() === parentBranch.data.key.toLowerCase());
										if (index === -1) {
											expandedBranches.push(parentBranch.data.key);
											preferencesStorage.setSortingFilteringParam("email",
												"expandedBranches",
												expandedBranches);
										}
									} else {
										var newLabel = newBranch.label.toLowerCase();
										var insertAt = 1;
										$.each($scope.tree.data,
											function (i, b) {
												if (i === 0) return true;

												insertAt = i;
												if (newLabel < b.label.toLowerCase()) {
													return false;
												}
											});
										$scope.tree.data.splice(insertAt, 0, newBranch);
									}
									//add folder to map
									if (!isShort) {
										$scope.tree.map[newBranch.data.key] = newBranch;
									} else {
										$scope.shortTree.map[newBranch.data.key] = newBranch;
									}
									//sort folders
									if (parentBranch) {
										parentBranch.children = coreDataMail.sortFolders(parentBranch.children);
									} else {
										$scope.tree.data = coreDataMail.sortFolders($scope.tree.data);
									}
								} else {
									if (params.response.data.message === "FOLDER_ALREADY_EXISTS") {
										errorHandling.report($translate.instant("FOLDER_ALREADY_EXISTS"));
									} else if (params.response.data.message === "WINDOWS_FILE_LENGTH_EXCEEDED") {
										errorHandling.report($translate.instant("WINDOWS_FILE_LENGTH_EXCEEDED"));
									} else {
										errorHandling.report($translate.instant("ACTION_FAILED"));
									}
								}
							}, function () { });
					}, errorHandling.report);
			} else {
				var currentBranchData = branch ? branch.data : { name: "", path: "", translatedName: ("- " + $filter("translate")("ROOT_FOLDER") + " -") };
				coreDataMail.getFolderList()
					.then(function (success) {
						var folderOwner = isRootFolder ? "" : currentBranchData.owner;

						$mdDialog
							.show({
								controller: "createFolderController",
								templateUrl: "app/email/modals/create-folder.dlg.html",
								targetEvent: ev,
								locals: { folders: success, parentFolder: currentBranchData, owner: folderOwner },
								clickOutsideToClose: false
							})
							.then(function (params) {
								if (params.success) {
									var parentFolder = params.folder.path.split("/");
									if (parentFolder.length > 1) {
										parentFolder.splice(parentFolder.length - 1, 1);
										parentFolder = parentFolder.join("/");
									} else {
										parentFolder = "";
									}
									parentFolder = parentFolder.toLowerCase();
									var newBranch = {
										label: params.name,
										data: {
											"mid": params.folder.id,
											"id": params.name,
											"label": params.name,
											"count": 0,
											"path": params.folder.path.toLowerCase(),
											"canDelete": true,
											"canEdit": true,
											"access": 0,
											"key": coreData.user.emailAddress + "|" + params.folder.path.toLowerCase(),
											"owner": coreData.user.emailAddress,
											"isMappedFolder": false,
											"isMappedSubfolder": false,
											"isShared": false,
										}
									};
									var isShort = false;
									var parentBranch = $scope.tree.map[coreData.user.emailAddress + "|" + parentFolder];
									if (!parentBranch && parentFolder !== "") {
										parentBranch =
											$scope.shortTree.map[coreData.user.emailAddress + "|" + parentFolder];
										isShort = true;
									}
									if (parentBranch != undefined) {
										if (!isShort) {
											$scope.treeController.add_branch(parentBranch, newBranch);
										} else {
											$scope.shortTreeController.add_branch(parentBranch, newBranch);
										}
										//expand folder
										var expandedBranches =
											preferencesStorage.getSortingFilteringParam("email", "expandedBranches") ||
											[];
										var index = expandedBranches.findIndex(e => e.toLowerCase() === parentBranch.data.key.toLowerCase());
										if (index === -1) {
											expandedBranches.push(parentBranch.data.key);
											preferencesStorage.setSortingFilteringParam("email",
												"expandedBranches",
												expandedBranches);
										}
									} else {
										var newLabel = newBranch.label.toLowerCase();
										var insertAt = 1;
										$.each($scope.tree.data,
											function (i, b) {
												if (i === 0) return true;

												insertAt = i;
												if (newLabel < b.label.toLowerCase()) {
													return false;
												}
											});
										$scope.tree.data.splice(insertAt, 0, newBranch);
									}
									//add folder to map
									if (!isShort) {
										$scope.tree.map[newBranch.data.key] = newBranch;
									} else {
										$scope.shortTree.map[newBranch.data.key] = newBranch;
									}
									//sort folders
									if (parentBranch) {
										parentBranch.children = coreDataMail.sortFolders(parentBranch.children);
									} else {
										$scope.tree.data = coreDataMail.sortFolders($scope.tree.data);
									}
								} else {
									if (params.response.data.message === "FOLDER_ALREADY_EXISTS") {
										errorHandling.report($translate.instant("FOLDER_ALREADY_EXISTS"));
									} else if (params.response.data.message === "WINDOWS_FILE_LENGTH_EXCEEDED") {
										errorHandling.report($translate.instant("WINDOWS_FILE_LENGTH_EXCEEDED"));
									} else {
										errorHandling.report($translate.instant("ACTION_FAILED"));
									}
								}
							}, function () { });
					}, errorHandling.report);
			}
		}

		function deleteFolderClickedDropdown(params) {
			$scope.deleteFolderClicked(params.event, params.branch);
		}

		function folderPropertiesClicked(event) {
			const branch = $scope.tree.selectedBranchData;
			$scope.openEditResourceModal(event, branch);
		}

		async function deleteFolderClicked(ev, branch) {
			var currentBranchData = branch ? branch.data : $scope.tree.selectedBranchData;
			if (!currentBranchData.canDelete) {
				errorHandling.warn($translate.instant('MAIL_FOLDER_CANNOT_BE_MODIFIED', { folder: currentBranchData.label }));
				return;
			}

			var isShort = false;
			var selectedBranch = $scope.tree.map[currentBranchData.key];
			if (!selectedBranch) {
				selectedBranch = $scope.shortTree.map[currentBranchData.key];
				isShort = true;
			}

			var confirm = $mdDialog.confirmDeletion()
				.htmlContent($filter('translate')('CONFIRMATIONS_DELETE_FOLDER', { name: (branch ? branch.label : selectedBranch.label) }))
				.targetEvent(ev);

			try {
				await $mdDialog.show(confirm);
			} catch (err) {
				/* Exit call if user cancels modal */
				return;
			}

			try {
				$rootScope.spinner.show();
				coreDataMail.ignoreFolderUpdate.requested = Date.now();

				if (currentBranchData.access === 3) {
					var parameters = {
						'folder': currentBranchData.path,
						'parentFolder': '',
						'ownerEmailAddress': currentBranchData.owner
					};
					await $http.post("~/api/v1/folders/delete-folder", parameters);
				} else {
					var parameters = {
						'folder': currentBranchData.path,
						'parentFolder': '',
					};
					await $http.post("~/api/v1/folders/delete-folder", parameters);
				}

				var parentFolder;
				var treeController = isShort ? $scope.shortTreeController : $scope.treeController;
				const deletedFolder = branch ? branch : treeController.get_selected_branch();
				const selectedFolder = treeController.get_selected_branch();

				if (deletedFolder === selectedFolder) {
					parentFolder = treeController.get_parent_branch(deletedFolder);
					if (parentFolder == undefined)
						parentFolder = treeController.get_prev_sibling(deletedFolder);

					if (isShort)
						preferencesStorage.setSessionSortingFilteringParam("email", "treeSelectedBranchData", {});

					$timeout(function () {
						if (parentFolder != null)
							treeController.select_branch(parentFolder);
						else
							treeController.select_branch(treeController.get_first_branch());
					});
				}

				treeController.remove_branch(deletedFolder);

				//expand folder
				var expandedBranches =
					preferencesStorage.getSortingFilteringParam('email', 'expandedBranches') || [];
				var index = expandedBranches.findIndex(e => e.toLowerCase() === deletedFolder.data.key.toLowerCase());
				if (index !== -1) {
					expandedBranches.splice(index, 1);
					preferencesStorage.setSortingFilteringParam('email', 'expandedBranches', expandedBranches);
				}
				if (parentFolder != null && parentFolder.children.length === 0) {
					index = expandedBranches.findIndex(e => e.toLowerCase() === parentFolder.data.key.toLowerCase());
					if (index !== -1) {
						expandedBranches.splice(index, 1);
						preferencesStorage.setSortingFilteringParam('email', 'expandedBranches', expandedBranches);
					}
				}
			}
			catch (err) {
				errorHandling.report("ACTION_FAILED");
			}
			finally {
				$rootScope.spinner.hide();
			}
		}

		function treeControllerAddToCount(key, num) {
			if ($scope.tree.map[key]) {
				let newCount = $scope.tree.map[key].data.count + num;
				if (newCount < 0)
					newCount = 0;
				$scope.tree.map[key].data.count = newCount;
			}
			if ($scope.shortTree.map[key]) {
				let newCount = $scope.shortTree.map[key].data.count + num;
				if (newCount < 0)
					newCount = 0;
				$scope.shortTree.map[key].data.count = newCount;
			}
			$rootScope.$broadcast('mail:unreadCountChanged');
		}

		function renameFolderClickedDropdown(params) {
			$scope.renameFolderClicked(params.event, params.branch);
		}

		function renameFolderClicked(ev, branch) {
			var currentBranchData = branch ? branch.data : $scope.tree.selectedBranchData;
			if (currentBranchData.access == 2 || !currentBranchData.canEdit) {
				errorHandling.warn($translate.instant('MAIL_FOLDER_CANNOT_BE_MODIFIED', { folder: currentBranchData.label }));
				return;
			}

			var folderToChangeKey = currentBranchData.key;
			var path = currentBranchData.path.split("/");
			var parentFolder = path.slice(0, -1).join('/');
			var currentFolder = path.slice(-1)[0];

			var name = branch
				? branch.data.isShared ? branch.data.id : branch.label
				: currentBranchData.isShared ? currentBranchData.id : currentBranchData.label;

			$mdDialog.show({
				controller: "editFolderController",
				templateUrl: 'app/email/modals/edit-folder.dlg.html',
				targetEvent: ev,
				locals: { name: name, currentFolder: currentFolder, parentFolder: parentFolder, owner: currentBranchData.owner },
				clickOutsideToClose: false
			})
				.then(function (params) {
					if (params && params.success) {
						var isShort = false;
						var newFolder = $scope.tree.map[folderToChangeKey];
						if (!newFolder) {
							newFolder = $scope.shortTree.map[folderToChangeKey];
							isShort = true;
						}
						newFolder.label = params.data.newName;
						newFolder.data.label = params.data.newName;
						newFolder.data.path = params.data.path;
						newFolder.data.id = params.data.newName;
						newFolder.data.canDelete = true;
						newFolder.data.canEdit = true;
						newFolder.data.access = 0;
						newFolder.data.key = coreData.user.emailAddress + "|" + params.data.path.toLowerCase();
						if (!isShort) {
							delete $scope.tree.map[folderToChangeKey];
							$scope.tree.map[newFolder.data.key] = newFolder;
						} else {
							delete $scope.shortTree.map[folderToChangeKey];
							$scope.shortTree.map[newFolder.data.key] = newFolder;
						}
						currentBranchData = newFolder.data;
						currentBranchData.label = newFolder.label;
						var inboxBranch = $scope.shortTree.map[coreData.user.emailAddress + "|inbox"];
						$scope.shortTreeController.deselect_all_branches();
						$scope.treeController.deselect_all_branches();
						$scope.shortTreeController.select_branch(inboxBranch);
						parentFolder = parentFolder.toLowerCase();
						//sort folders
						var parentBranch = $scope.tree.map[coreData.user.emailAddress + '|' + parentFolder];
						if (!parentBranch) { parentBranch = $scope.shortTree.map[coreData.user.emailAddress + '|' + parentFolder]; }
						if (parentBranch) {
							parentBranch.children = coreDataMail.sortFolders(parentBranch.children);
						} else {
							$scope.tree.data = coreDataMail.sortFolders($scope.tree.data);
						}
						if (newFolder.children && newFolder.children.length > 0) {
							reloadSources();
						}

						//expand folder
						var expandedBranches = preferencesStorage.getSortingFilteringParam('email', 'expandedBranches') || [];
						var index = expandedBranches.findIndex(e => e.toLowerCase() === folderToChangeKey.toLowerCase());

						if (index !== -1)
							expandedBranches.splice(index, 1);
						expandedBranches.push(newFolder.data.key);
						preferencesStorage.setSortingFilteringParam('email', 'expandedBranches', expandedBranches);
						currentBranchData.expanded = true;

					} else {
						errorHandling.report(params.response);
					}
				}, function () { });
		}

		function moveFolderClickedDropdown(params) {
			$scope.moveFolderClicked(params.event, params.branch);
		}

		function moveFolderClicked(ev, branch) {
			var currentBranchData = branch ? branch.data : $scope.tree.selectedBranchData;
			if (currentBranchData.access == 2 || !currentBranchData.canEdit) {
				errorHandling.warn($translate.instant('MAIL_FOLDER_CANNOT_BE_MODIFIED', { folder: currentBranchData.label }));
				return;
			}

			var folderToChangeKey = currentBranchData.key;
			var path = currentBranchData.path.split("/");
			var parentFolder = path.slice(0, -1).join('/');
			var currentFolder = path.slice(-1)[0];
			var name = branch ? branch.label : currentBranchData.label;

			coreDataMail.getFolderList(parentFolder, currentBranchData.path)
				.then(function (success) {
					const sortedFolders = success.sort(function (a, b) {
						return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
					});
					$mdDialog.show({
						controller: "moveFolderController",
						templateUrl: 'app/email/modals/move-folder.dlg.html',
						targetEvent: ev,
						locals: { folders: sortedFolders, folder: currentBranchData.path },
						clickOutsideToClose: false
					})
						.then(function (params) {
							var parameters = {
								'folder': currentFolder,
								'newFolder': name,
								"parentFolder": parentFolder,
								"newParentFolder": params.folder
							};
							$http.post("~/api/v1/folders/folder-patch", parameters)
								.then(function () {
									var newKey = currentBranchData.owner + "|" + (!!parameters.newParentFolder ? (parameters.newParentFolder + "/") : "") + parameters.folder;
									vm.folderKeyChangeData = {};
									vm.folderKeyChangeData[folderToChangeKey] = newKey;
								}, function (response) {
									errorHandling.report(response);
								});
						}, function () { });
				}, errorHandling.report);
		}

		function purgeMarked(ev) {
			if ($scope.totalItems == 0) {
				errorHandling.warn("NO_MESSAGES_IN_FOLDER");
				return;
			}

			var branch = $scope.selectedBranch;
			if (!branch) {
				branch = $scope.treeController.get_selected_branch();
				if (!branch) {
					branch = $scope.shortTreeController.get_selected_branch();
				}
			}

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_PURGE_MARKED_DELETED', { name: branch.label }))
				.targetEvent(ev);

			$mdDialog.show(confirm)
				.then(function () {
					var parameters = {
						'folder': $scope.getActiveFolder(),
						'ownerEmailAddress': $scope.getActiveFolderOwner(),
					};
					if ($rootScope.spinner) {
						$rootScope.spinner.show();
					}
					$http.post("~/api/v1/mail/purge-marked-deleted", parameters)
						.then(function () {
							coreDataMail.resyncFolderUnreadCount($scope.getActiveFolder(), $scope.getActiveFolderOwner());
							$scope.mailPanelGrid.reset({ keepSelection: false });
							if ($rootScope.spinner) {
								$rootScope.spinner.hide();
							}
						}, function (failure) {
							if ($rootScope.spinner) {
								$rootScope.spinner.hide();
							}
							errorHandling.report(failure.data.message);
						});
				});
		}

		function deleteAllMessagesInSelectedBranch(ev) {
			$scope.deleteAllMessages(ev, $scope.tree.selectedBranchData);
		}

		function deleteAllMessagesContext(params) {
			$scope.deleteAllMessages(params.event, params.branchData);
		}

		function deleteAllMessages(ev, branchData) {

			if (!branchData || branchData.totalMessages < 1) {
				return;
			}

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_MESSAGES_ALL", { count: branchData.totalMessages, folder: branchData.label }))
				.targetEvent(ev);
			$mdDialog.show(confirm)
				.then(function () {
					var parameters = {
						'folder': branchData.path,
						'ownerEmailAddress': branchData.owner,
						'all': true
					};

					if ($rootScope.spinner) {
						$rootScope.spinner.show();
					}
					//$scope.inActionProgess = true;
					$localStorage.ignoreMailSignal = parameters.folder;
					$scope.ignoreSignalUids.push(-1);
					$http.post("~/api/v1/mail/delete-messages", parameters)
						.then(function (response) {
							if ($rootScope.spinner) {
								$rootScope.spinner.hide();
							}
							if (response.data.actionGuid) {
								//var progress = $mdDialog.stProgressBar()
								//	.title($translate.instant("PROGRESS"))
								//	.barText($translate.instant("DELETING"))
								//	.showCancel(false)
								//	.enableButtons(false)
								//	.autoClose(true)
								//	.percentProgress(0)
								//	.guid(response.data.actionGuid)
								//	.progressListener("signalR.mailHub.client.mailAction");
								//$mdDialog.show(progress).then(function () {
								//	onDeleteAllInFolderEnd(branch);
								//}, function () {
								//	onDeleteAllInFolderEnd(branch);
								//});
							} else {
								onDeleteAllInFolderEnd(branchData);
							}
						}, function (failure) {
							if ($rootScope.spinner) {
								$rootScope.spinner.hide();
							}
							$localStorage.ignoreMailSignal = undefined;
							$scope.inActionProgess = false;
							errorHandling.report($translate.instant(failure.data.message));
						});
				});
		}

		function onDeleteAllInFolderEnd(branch) {
			setSelectMode(false);
			branch.count = 0;
			$localStorage.ignoreMailSignal = undefined;
			$scope.mailPanelGrid.reset({ keepSelection: false });
			$log.debug("DeleteAllInFolderEnd");
			$scope.$broadcast("unloadMessage", {});
			messageData.messageLoaded = false;
			$scope.inActionProgess = false;
		}

		function disposableAddressDropdown(params) {
			$scope.openDisposableAddress(params.event, params.branch);
		}

		function editResourceDropdown(params) {
			$scope.openEditResourceModal(params.event, params.branch.data);
		}

		function goToManageShares(params) {
			$state.go("index.settings.user-sharing");

		}

		function openDisposableAddress(ev, branch) {
			if (!vm.disposableAddressEnabled) {
				return;
			}
			var currentBranchData = branch ? branch.data : $scope.tree.selectedBranchData;
			var folderPath = currentBranchData.path;
			$mdDialog
				.show({
					controller: "disposableAddressController",
					templateUrl: "app/email/modals/disposable-address.dlg.html",
					targetEvent: ev,
					locals: {
						alias: undefined,
						coreDataMail: coreDataMail,
						currentFolder: folderPath
					},
					clickOutsideToClose: false
				})
				.then(function (success) {
					var key;
					var mapKey = coreData.user.emailAddress + "|" + success;

					for (key in $scope.tree.map) {
						$scope.tree.map[key].data.isDisposable = (key === mapKey);
					}

					for (key in $scope.shortTree.map) {
						$scope.shortTree.map[key].data.isDisposable = (key === mapKey);
					}

					refreshDisposableAddress();
				}, function () { });
		}

		async function refreshDisposableAddress() {
			try {
				const data = await coreDataMail.Data.disposableAddress;
				const newDisposableAddress = data
					? {
						alias: data.address,
						folder: data.folder,
						expireTime: data.expireTime,
						expires: moment.duration(data.expireTime - moment()),
						expiresString: data.expireTime.toNow(true)
					}
					: null;

				$scope.$applyAsync(() => vm.disposableAddress = newDisposableAddress);
			} catch (err) { }
		}

		function runFilter(ev, branch) {
			if (!vm.contentFilters || vm.contentFilters.length === 0) {
				return;
			}

			$mdDialog
				.show({
					controller: "filterRunCurrentFolder",
					templateUrl: "app/email/modals/filter-run-currentFolder-dlg.html",
					targetEvent: ev,
					locals: {
						selectedFolder: branch,
						folderLabel: branch.label
					},
					clickOutsideToClose: false
				})
				.then(function (success) {
					var toRun = [success.filter.id];
					var params = JSON.stringify({ toRun: toRun, folder: success.folder });
					$http
						.post("~/api/v1/settings/run-content-filters/", params)
						.then(
							function (success) {
								vm.runningFilters = true;
								contentFilterData.contentFilterProgress(null,
									{
										guid: success.data.result,
										count: -1,
										total: -1,
										done: false,
										success: true
									});
							}, errorHandling.report);
				}, function () { });
		}

		function runFilterDropdown(params) {
			runFilter(params.event, $scope.tree.selectedBranchData);
		}

		function runFilterContext(params) {
			runFilter(params.event, params.branch.data);
		}

		function onFilterRunningUpdate(event, data) {
			if (data.done) {
				vm.runningFilters = false;
			}
		}

		function hasFullControl() {
			return !$scope.tree || !$scope.tree.selectedBranchData || !$scope.tree.selectedBranchData.access || $scope.tree.selectedBranchData.access === 1 || $scope.tree.selectedBranchData.access === 3;
		}

		function hasReadControl() {
			return !$scope.tree || !$scope.tree.selectedBranchData || !$scope.tree.selectedBranchData.access || $scope.tree.selectedBranchData.access <= 3;
		}

		function allowRunFilter() {
			var result = $scope.tree &&
				$scope.tree.selectedBranchData &&
				!$scope.tree.selectedBranchData.isMappedFolder &&
				!$scope.tree.selectedBranchData.isMappedSubfolder &&
				$scope.tree.selectedBranchData.id !== "Drafts";

			return result;
		}

		function loadMailTree(forceReload) {
			if (!forceReload) { forceReload = false; }
			var promises = [coreDataMail.loadMailTree($scope.treeController, forceReload)];
			$q.all(promises)
				.then(function (response) {
					$scope.treeController.deselect_all_branches();
					$scope.shortTreeController.deselect_all_branches();
					//$log.debug('---MAIL TREE LOADED---');
					$timeout(function () {
						var key = coreDataMail.lastSelectedBranchData ? coreDataMail.lastSelectedBranchData.key : '';
						//$log.debug('Last selected key: ' + key);
						var branch = $scope.tree.map[key];
						var isShort = false;
						if (!branch) {
							branch = $scope.shortTree.map[key];
							isShort = true;
						}
						if (!branch) {
							branch = $scope.shortTree.map[coreData.user.emailAddress + "|inbox"];
							isShort = true;
						}
						//$log.debug('Branch to select:')
						//$log.debug(branch);
						if (response[0] == false) {
							//We loaded from cache.
							$timeout(function () {
								if (!branch) {
									$scope.shortTreeController.select_first_branch();
								} else {
									if (isShort) {
										$scope.shortTreeController.select_branch(branch);
										$scope.tree.selectedBranchData = branch.data;
										checkSpecialFolders();
									} else {
										$scope.treeController.select_branch(branch);
										$scope.tree.selectedBranchData = branch.data;
										checkSpecialFolders();
									}
								}
								loadMailList();
							});
						} else {
							//$log.debug('All branches deslected');
							if (!branch) {
								//$log.debug('No branch, selecting first branch of short tree.');
								$scope.shortTreeController.select_first_branch();
								$scope.tree.selectedBranchData = $scope.shortTreeController.get_selected_branch().data;
							} else {
								if (isShort) {
									//$log.debug('Selecting branch on short tree');
									//$log.debug(branch);
									$scope.shortTreeController.select_branch(branch);
									$scope.tree.selectedBranchData = branch.data;
									checkSpecialFolders();
								} else {
									//$log.debug('Selecting branch on tree');
									//$log.debug(branch);
									$scope.treeController.select_branch(branch);
									$scope.tree.selectedBranchData = branch.data;
									checkSpecialFolders();
								}
							}
							loadMailList();
						}
						$rootScope.$broadcast('mail:unreadCountChanged');
					});
				});
		}

		function loadMailList() {
			//$log.debug('-----Loading Mail List-----');
			vm.mailSearchCriteria.folder = $scope.tree.selectedBranchData.path;
			vm.mailSearchCriteria.ownerEmailAddress = $scope.tree.selectedBranchData.owner;
			$rootScope.spinner.show();
			$("#MailPanelGrid").empty();
			$scope.mailPanelGrid = undefined;
			$scope.mailPanelGrid = new window.SmarterTools.PanelGrid(
				$("#MailPanelGrid"),
				{
					searchApiUrl: coreData.baseUrl + "/api/v1/mail/messages",
					searchApiUrlIdOnly: coreData.baseUrl + "/api/v1/mail/messages-uid",
					searchCriteria: vm.mailSearchCriteria,
					itemsToGrabAtInit: (($scope.mailList.filterType === 0 && $scope.mailList.filterValue === false) ? 40000 : 100),
					renderItemHandler: buildGridItem,
					getAccessTokenHandler: getAccessToken,
					itemHeight: 64,
					onItemClick: clickGridItem,
					onItemDoubleClick: doubleClickGridItem,
					onRightClick: contextMenuGridItem,
					onDeleteKey: onDeleteKey,
					onSelectionCountChanged: onSelectionCountChanged,
					onGetDataCompleted: onGetDataCompleted,
					translations: {
						noItemsToShow: $translate.instant("NO_ITEMS_TO_SHOW"),
						pleaseWait: $translate.instant("LOADING")
					}
				}
			);

			//if (!$scope.inActionProgess) {
			//    $timeout(function () {
			//        $scope.mailPanelGrid.reset({ keepSelection: false });
			//    });
			//}
		}

		function resetMailList() {
			$scope.refreshEmailList();
		}

		function unloadMessage() {
			if (messageData.messageLoaded) {
				messageData.navigationPacket = {};
				$scope.$broadcast("unloadMessage", {});
				messageData.messageLoaded = false;
			}
		}

		function onBranchChanged(branch, isShort) {
			firstRun = true;
			vm.markAfterPopout = false;
			if ($scope.tree.selectedBranchData.path != branch.data.path || $scope.tree.selectedBranchData.owner != branch.data.owner) {
				$rootScope.$broadcast('mail.mailListUnloaded');

				vm.listLoaded = false;
				vm.selectedCount = 0;
				$scope.totalItems = 0;
				unloadMessage();
				$timeout(function () {
					$scope.tree.selectedBranchData = branch.data;
					apiCategories.categoryOwner = getActiveFolderOwner();
					checkSpecialFolders();
					preferencesStorage.setSessionSortingFilteringParam("email", "treeSelectedBranchData", branch.data);
					coreDataMail.lastSelectedBranchData = $scope.tree.selectedBranchData;
					setSelectMode(false);
					coreDataMail.searchText = "";
					$scope.searchText = coreDataMail.searchText;
					vm.mailSearchCriteria.query = coreDataMail.searchText;
					resetSortingAndFilters(false);
					vm.mailSearchCriteria.folder = branch.data.path;
					vm.mailSearchCriteria.ownerEmailAddress = branch.data.owner;
					if (!$scope.inActionProgess) {
						$scope.mailPanelGrid.reset({ keepSelection: false });
					}
				});
			}
		}

		function checkSpecialFolders() {
			try {
				if (!$scope.tree || !$scope.tree.selectedBranchData || !$scope.tree.selectedBranchData.path) {
					return;
				}

				const selectedPathLower = $scope.tree.selectedBranchData.path.toLowerCase();
				$scope.tree.selectedBranchData.lowerPath = selectedPathLower;
				vm.isInJunk = (selectedPathLower.indexOf('junk e-mail') !== -1);
				vm.isInDeleted = (selectedPathLower.indexOf('deleted items') !== -1);
				vm.isInSentItems = (selectedPathLower.indexOf('sent items') !== -1);
				vm.isInDrafts = (selectedPathLower.indexOf('drafts') !== -1);
			} catch (e) {
				return;
			}
		}

		function searchClicked() {
			coreDataMail.searchText = $scope.searchText;
			vm.mailSearchCriteria.query = coreDataMail.searchText;
			vm.skipNextMarkRead = true;
			resetMailList();
		}

		function onSortingChanged(val, updateList) {
			vm.sortBy = val;
			recalculateSorting(updateList);
		}

		function onSortOrderChanged(val, updateList) {
			vm.sortReverse = val;
			recalculateSorting(updateList);
		}

		function recalculateSorting(updateList) {
			/*	From API:
				Date = 0, // sent date
				Size = 1,
				Subject = 2,
				From = 3,
				To = 4,
				InternalDate = 5, // received date
				Attachment = 6
			*/
			switch (vm.sortBy) {
				case "size":
					$scope.mailList.sortType = 1;
					$scope.mailList.sortAscending = vm.sortReverse;
					break;
				case "subject":
					$scope.mailList.sortType = 2;
					$scope.mailList.sortAscending = !vm.sortReverse;
					break;
				case "from":
					$scope.mailList.sortType = 3;
					$scope.mailList.sortAscending = !vm.sortReverse;
					break;
				case "to":
					$scope.mailList.sortType = 4;
					$scope.mailList.sortAscending = !vm.sortReverse;
					break;
				case "attachment":
					$scope.mailList.sortType = 6;
					$scope.mailList.sortAscending = vm.sortReverse;
					break;
				case "internalDate":
				default:
					$scope.mailList.sortType = 5;
					$scope.mailList.sortAscending = vm.sortReverse;
					break;
			}

			vm.mailSearchCriteria.sortType = $scope.mailList.sortType;
			vm.mailSearchCriteria.sortAscending = $scope.mailList.sortAscending;

			if (updateList && !$scope.inActionProgess) {
				$scope.mailPanelGrid.reset({ keepSelection: false });
			}
		}

		function onFilterChanged(val) {
			/*	From API:
				Read = 0,
				Replied = 1,
				Forwarded = 2,
				Deleted = 3,
				Flagged = 4,
				Draft = 5,
				Recent = 6,
				Attachments = 7,
				Tasks = 8
			 */
			switch (val) {
				case 'unread':
					$scope.mailList.filterType = 0;
					$scope.mailList.filterValue = false;
					break;
				case 'read':
					$scope.mailList.filterType = 0;
					$scope.mailList.filterValue = true;
					break;
				case 'replied':
					$scope.mailList.filterType = 1;
					$scope.mailList.filterValue = true;
					break;
				case 'notReplied':
					$scope.mailList.filterType = 1;
					$scope.mailList.filterValue = false;
					break;
				case 'followUp':
					$scope.mailList.filterType = 4;
					$scope.mailList.filterValue = true;
					break;
				case 'attachments':
					$scope.mailList.filterType = 7;
					$scope.mailList.filterValue = true;
					break;
				case 'tasks':
					$scope.mailList.filterType = 8;
					$scope.mailList.filterValue = true;
					break;
				case 'all':
				default:
					$scope.mailList.filterType = undefined;
					$scope.mailList.filterValue = true;
					break;
			}
			vm.skipNextMarkRead = true;
			vm.mailSearchCriteria.searchFlags = undefined;
			if ($scope.mailList.filterType != undefined) {
				vm.mailSearchCriteria.searchFlags = {};
				vm.mailSearchCriteria.searchFlags[$scope.mailList.filterType] = $scope.mailList.filterValue;
			}
			//if the filter is by unread we want the list to load all messages.
			if ($scope.mailList.filterType === 0 && $scope.mailList.filterValue === false) {
				$scope.mailPanelGrid.setOptions({ itemsToGrabAtInit: 40000 });
			} else {
				$scope.mailPanelGrid.setOptions({ itemsToGrabAtInit: 100 });
			}
			if (!$scope.inActionProgess) {
				$scope.mailPanelGrid.reset({ keepSelection: false });
			}
		}

		function resetSortingAndFilters(updateList) {
			if (updateList === undefined) { updateList = true; }
			if (!$scope.mailList.sortType)
				vm.onSortingChanged("internaldate", updateList);
			if (!$scope.mailList.sortAscending)
				vm.onSortOrderChanged(!true, updateList);
		}

		async function printMessage() {
			try {
				await emailFunctions.showPrintPopup(messageData.message);
			} catch (err) {
				errorHandling.report(err);
			}
		}

		async function popout(id, markRead) {
			if (!markRead) { markRead = false; }
			if (id == undefined)
				id = $scope.activeEmailID;

			const packet = emailNavigation.makeEmailPacket($scope.tree.selectedBranchData.owner, $scope.tree.selectedBranchData.path, id);
			const url = emailNavigation.getPopoutUrl(packet);
			popoutWindow = window.open(url,
				"emailID" + id,
				'resizable=1, ' + popupService.dimensions.email);

			popoutWindow.onbeforeunload = function () {
				if (typeof popoutWindow.st_onbeforeunload === 'function')
					popoutWindow.st_onbeforeunload();
				//$rootScope.$broadcast('user-settings:changed');
				vm.markAfterPopout = true;
			};
		}

		function refreshEmailList() {
			//$log.debug("refreshEmailList");
			if (!$scope.inActionProgess) {
				$scope.mailPanelGrid.reset({ keepSelection: false });
			}
		}

		// Arrow key list navigation
		function newEmail() {
			const packet = emailNavigation.makeComposePacket();
			const url = emailNavigation.getPopoutComposeUrl(packet);
			popoutWindow = window.open(url, "", 'resizable=1, ' + popupService.dimensions.email);
		}

		//// Misc Functions
		function checkForAvailableMappings(reset) {
			if (reset) coreDataSettings.resetResources();
			coreDataSettings.settingsData.mappedResources
				.then(function (success) {
					// Remove all non-note or mapped resources
				});
		}

		function reloadSources() {
			for (let key in $scope.tree.map) {
				if ($scope.tree.map[key] && $scope.tree.map[key].expanded)
					coreDataMail.expansionList[key] = true;
			}
			for (let key in $scope.shortTree.map) {
				if ($scope.shortTree.map[key] && $scope.shortTree.map[key].expanded)
					coreDataMail.expansionList[key] = true;
			}

			coreDataMail.loadMailTree($scope.treeController, true)
				.then(function () {
					$timeout(function () {
						var currentBranchKey = $scope.tree.selectedBranchData.key;
						$scope.tree = coreDataMail.getMailTree();
						$scope.shortTree = coreDataMail.getShortMailTree();
						var isShort = false;
						if (vm.folderKeyChangeData && vm.folderKeyChangeData[currentBranchKey])
							currentBranchKey = vm.folderKeyChangeData[currentBranchKey];
						var branch = $scope.tree.map[currentBranchKey];
						if (!branch) {
							branch = $scope.shortTree.map[currentBranchKey];
							isShort = true;
						}
						if (!branch) {
							branch = $scope.tree.map[coreData.user.emailAddress + "|inbox"] || $scope.shortTreeController.get_first_branch();
							if (branch.data.key === coreData.user.emailAddress + "|inbox") {
								isShort = true;
							}
						}
						if (branch.data.key !== currentBranchKey) {
							$scope.onBranchChanged(branch, isShort);
						}
						if (!isShort) {
							$scope.treeController.select_branch(branch);
						} else {
							$scope.shortTreeController.select_branch(branch);
						}

						var selectedBranch = $scope.treeController.get_selected_branch();
						var newBranchData = selectedBranch && selectedBranch.data;
						if (!newBranchData) {
							selectedBranch = $scope.shortTreeController.get_selected_branch();
							newBranchData = selectedBranch && selectedBranch.data;
						}
						if (newBranchData)
							$scope.tree.selectedBranchData = newBranchData;
					});
				}, function () {
					errorHandling.report($translate.instant("ERROR_LOADING_DIRECTORY_TREE"));
				});
		}

		function closeMessagePane() {
			$scope.showMessagePane = !$scope.showMessagePane;
			unloadMessage();
			$timeout(function () {
				$scope.mailPanelGrid.repaint();
				$timeout(function () {
					$scope.mailPanelGrid.repaint();
					$scope.mailPanelGrid.focusItem();
				}, 100);
			});
		}

		function nextEmail(event) {
			var activeEmail = $scope.mailPanelGrid.getItemById(messageData.navigationPacket.uid);
			if (!activeEmail) {
				return;
			}
			$scope.mailPanelGrid.selectNext(event);
		}

		function previousEmail(event) {
			var activeEmail = $scope.mailPanelGrid.getItemById(messageData.navigationPacket.uid);
			if (!activeEmail) {
				return;
			}
			$scope.mailPanelGrid.selectPrevious(event);
		}

		//// Resizable Mail List
		function setupResizableMailList() {
			if (vm.previewPaneOption === "right") {
				vm.mailListView = $("#mailListView");
				if (localStorage.resizableMailListWidth) {
					vm.mailListView.css("min-width", localStorage.resizableMailListWidth);
					vm.mailListView.css("max-width", localStorage.resizableMailListWidth);
				}
				$(".mail-list-resize-handle").on("mousedown", startHorizResize);
			}
		}

		function startHorizResize(event) {
			event.preventDefault();
			$("body").append('<div class="resize-cover"></div>');

			vm.startX = event.clientX;
			vm.startWidth = Number(vm.mailListView.css("min-width").replace('px', ''));

			if (isNaN(vm.startWidth)) vm.startWidth = 330;

			window.addEventListener("mousemove", duringHorizResize);
			window.addEventListener("mouseup", stopHorizResize);
		}

		function duringHorizResize(event) {
			event.preventDefault();

			var newWidth = vm.startWidth + (event.clientX - vm.startX);
			newWidth = newWidth < 330 ? 330 : (newWidth > 450 ? 450 : newWidth);

			vm.mailListView.css("min-width", newWidth + "px");
			vm.mailListView.css("max-width", newWidth + "px");
		}

		function stopHorizResize(event) {
			event.preventDefault();
			$(".resize-cover").remove();
			localStorage.resizableMailListWidth = vm.mailListView.css("min-width");

			// Refresh maillist here
			if ($scope.listController)
				$scope.listController.refresh();

			window.removeEventListener("mousemove", duringHorizResize);
			window.removeEventListener("mouseup", stopHorizResize);
		}

		function duringVertResize(event) {
			event.preventDefault();
			event.stopPropagation();

			var newHeight = vm.startHeight + (event.clientY - vm.startY);
			newHeight = newHeight < 188 ? 188 : (newHeight > 476 ? 476 : newHeight);

			vm.mailListView.css("min-height", newHeight + "px");
			vm.mailListView.css("max-height", newHeight + "px");
		}

		function stopVertResize(event) {
			event.preventDefault();
			$(".resize-cover").remove();
			localStorage.resizableMailListHeight = vm.mailListView.css("min-height");

			// Refresh maillist here
			if ($scope.listController)
				$scope.listController.refresh();

			window.removeEventListener("mousemove", duringVertResize);
			window.removeEventListener("mouseup", stopVertResize);
		}

		//Mapped Resources
		async function openSharingModal(ev, source) {
			try {
				if (source.mapped || source.isMappedFolder || source.isMappedSubfolder) {
					await $mdDialog.show({
						controller: "editSharedFolderDialogController",
						controllerAs: "ctrl",
						templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
						targetEvent: ev,
						clickOutsideToClose: false,
						locals: { folderId: source.folderId, webcalLink: null, folder: null }
					});
				}
				else {
					await $mdDialog.show({
						controller: "editOwnShareDialogController",
						controllerAs: "ctrl",
						templateUrl: "app/shared/modals/edit-own-share.dlg.html",
						targetEvent: ev,
						locals: { folderId: source.folderId, folder: null }
					});
				}

				$timeout(function () {
					checkForAvailableMappings(true);
					reloadSources();
				});
			} catch (err) {
				/* Ignore exceptions thrown when cancelling modal */
			}
		}

		function openEditResourceModal(ev, branchData) {
			if (!branchData) {
				branchData = $scope.tree.selectedBranchData;
			}
			if (!branchData) {
				branchData = $scope.treeController.get_selected_branch().data;
				if (branch) { branch = branch.data; }
			}
			if (!branchData) {
				branchData = $scope.shortTreeController.get_selected_branch().data;
				if (branch) { branch = branch.data; }
			}
			coreDataSettings.settingsData.mappedResources
				.then(function (success) {
					var folderSource;
					if (success) {
						for (var i = 0; i < success.length; i++) {
							if (success[i].shareType == 1 && success[i].itemID.toLowerCase() === branchData.path.toLowerCase() && success[i].ownerUsername === branchData.owner.split('@')[0]) { folderSource = success[i]; break; }
						}
					}

					if (folderSource) {
						$scope.openSharingModal(ev, folderSource);
						return;
					}

					coreDataSettings.settingsData.resources
						.then(function (success) {
							for (var i = 0; i < success.length; i++) {
								if (success[i].shareType === 1 && success[i].resourceName.toLowerCase() === branchData.path.toLowerCase()) {
									folderSource = {
										access: 8,
										friendlyName: branchData.label,
										id: success[i].resourceName,
										isBeingShared: branchData.isBeingShared,
										owner: userDataService.user.username,
										folderId: branchData.mid,
										isMappedFolder: branchData.isMappedFolder,
										isMappedSubfolder: branchData.isMappedSubfolder
									};
									break;
								}
							}

							if (folderSource) { $scope.openSharingModal(ev, folderSource); return; }

							folderSource = {
								access: 8,
								friendlyName: branchData.label,
								id: branchData.path,
								isBeingShared: false,
								owner: userDataService.user.username,
								untranslatedName: branchData.untranslatedName,
								folderId: branchData.mid,
								isMappedFolder: branchData.isMappedFolder,
								isMappedSubfolder: branchData.isMappedSubfolder
							};

							if (folderSource) {
								$scope.openSharingModal(ev, folderSource);
								return;
							}

						}, function () { });

				}, function () { });
		}

		function getDisplayTime(date) {
			var newDate = new Date(date);
			if (newDate.getSeconds() >= 30) {
				newDate.setMinutes(newDate.getMinutes() + 1);
			}
			newDate.setSeconds(0);
			return newDate;
		}

		function onPopState(e) {
			if (e.state == null || e.state.showMessage == null)
				return;

			// Do this to prevent trying to go forward. If the message pane isn't showing now, don't switch to it.
			if (!$scope.showMessagePane)
				return;

			$scope.showMessagePane = e.state.showMessage;

			if (!$scope.showMessagePane) {
				unloadMessage();
				$timeout(function () {
					$scope.mailPanelGrid.repaint();
					$timeout(function () {
						$scope.mailPanelGrid.repaint();
						$scope.mailPanelGrid.focusItem();
					},
						100);
				});
			}
		}

		function visibilityCheckShareFolder(branch) {
			return branch.data.id != 'Drafts' && (!branch.data.isShared || branch.data.isBeingShared) && coreDataSettings.userDomainSettings.enableSharing;
		}
	}

})();
