(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userFilterActionEditController", userFilterActionEditController);

	function userFilterActionEditController($scope, $mdDialog, $filter, $timeout, $translate, $http, userDataService, coreDataSettings, coreDataDomainSettings, oldAction, newAction, filterActions, usedActions, folders, validateMail, type, toaster, claimsService, errorHandling) {
		$scope.oldAction = oldAction;
		$scope.newAction = newAction;
		$scope.hasSelectedAction = !$scope.newAction;
		$scope.availableActions = filterActions;
		$scope.currentAction = undefined;
		$scope.usedActions = usedActions;
		$scope.folders = folders;
		$scope.type = type;
		$scope.textInput = '';
		$scope.selectedFolderText = 'Inbox';
		$scope.prioritySelection = 'low';
		$scope.isValid = true;
		$scope.addressList = [{ value: "" }];
		$scope.keepRecipients = false;
		$scope.deleteOriginal = false;

		// Functions
		$scope.getCurrentAction = getCurrentAction;
		$scope.getPriTranslation = getPriTranslation;
		$scope.loadArguments = loadArguments;
		$scope.buildSaveAction = buildSaveAction;
		$scope.save = save;
		$scope.addEmail = addEmail;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;
		$scope.emailDelete = emailDelete;

		activate();

		///////////////////////////////

		function activate() {
			if (!$scope.newAction) {
				angular.forEach(usedActions, function (value) {
					$scope.availableActions = $.grep($scope.availableActions, function (action) {
						if (action.id === value.actionType) {
							return action.id === $scope.oldAction.actionType;
						}
						return true;
					});
				});
			} else {
				angular.forEach(usedActions, function (value) {
					$scope.availableActions = $.grep($scope.availableActions, function (action) { return action.id !== value.actionType; });
				});
			}

			$scope.folders = _.filter($scope.folders, function (f) {
				return f.ownerEmailAddress === userDataService.user.emailAddress;
			});

			$scope.availableActions = $.grep($scope.availableActions, function (action) { return action.id !== 0 });
			if (!$scope.newAction) {
				$scope.getCurrentAction();
				$scope.loadArguments();
			}
			
			$scope.$watch('currentAction', onCurrentActionChanged);
			$scope.$watch('textInput', onTextInputChanged);
		};

		function getCurrentAction() {
			var findAction = $.grep($scope.availableActions, function (action) { return action.id === $scope.oldAction.actionType });
			if (findAction.length > 0) {
				$scope.currentAction = findAction[0];
			}
		};

		function onCurrentActionChanged() {
			if ($scope.currentAction) {
				$scope.hasSelectedAction = true;
				if ($scope.currentAction.id === $scope.oldAction.fieldType && !$scope.newAction) {
					$scope.loadArguments();
				} else if ($scope.newAction) {
					for (var index = 0; index < $scope.folders.length; index++) {
						if ($scope.folders[index].name === 'Inbox') {
							$scope.selectedFolder = $scope.folders[index];
							if ($scope.selectedFolder.translatedName)
								$scope.selectedFolder.translatedName = newVal.translatedName.replaceAll("\u00A0", '');
							if ($scope.selectedFolder.name)
								$scope.selectedFolder.name = newVal.name.replaceAll("\u00A0", '');
							$scope.selectedFolderText = $scope.selectedFolder.translatedName != undefined ? $filter('folderTranslate')($scope.selectedFolder.translatedName) : $scope.selectedFolder.selectedFolder.name;
							break;
						}
					}
					$scope.textInput = '';
					$scope.prioritySelection = 'low';
				}

				if ($scope.currentAction.id === 4 && $scope.type === "domain") {
					$scope.currentAction.inputTranslate = "FOLDER";
				}
			}
		};

		function getPriTranslation(priority) {
			switch (priority) {
				case 'low':
					return $filter('translate')('LOW');
				case 'normal':
					return $filter('translate')('NORMAL');
				case 'high':
					return $filter('translate')('HIGH');
			}
		};

		function loadArguments() {
			switch ($scope.currentAction.id) {
				default:
				case 0:
				case 1:
				case 3:
				case 8:
				case 10:
					break;
				case 4:
					if ($scope.type === "domain")
					{
						$scope.currentAction.inputTranslate = "FOLDER";
						$scope.textInput = $scope.oldAction.argument;
						break;
					}
					let result;
					var name = $scope.oldAction.argument;
					for (var index = 0; index < $scope.folders.length; index++) {
						let tempName = $scope.folders[index].path;
						if (tempName.replaceAll("\u00A0", '') === name) {
							result = $scope.folders[index];
							break;
						}
					}
					$scope.selectedFolder = result;
					$scope.selectedFolderText = $scope.selectedFolder.translatedName != undefined ? $filter('folderTranslate')($scope.selectedFolder.translatedName.replaceAll("\u00A0", '')) : $scope.selectedFolder.name.replaceAll("\u00A0", '');
					break;
				case 5:
				case 6:
					$scope.textInput = $scope.oldAction.argument;
					break;
				case 7:
				case 2:	// <--- this case should be obsolete
					var split = $scope.oldAction.argument.split(';');
					$scope.addressList = $.map(split, function (address) { return { value: address } });
					$scope.addressList.push({value: ''});
					
					$scope.keepRecipients = $scope.oldAction.boolOption1;
					$scope.deleteOriginal = $scope.oldAction.boolOption2;
					break;
				case 9:
					$scope.prioritySelection = $scope.oldAction.argument;
					break;
			}
		};

		function buildSaveAction() {
			switch ($scope.currentAction.id) {
				default:
				case 0:
				case 1:
				case 3:
				case 8:
				case 10:
					return { actionType: $scope.currentAction.id, argument: '', isDefaultAction: false };
				case 4:

					if ($scope.type === "domain")
						return { actionType: $scope.currentAction.id, argument: $scope.textInput, isDefaultAction: false };
					else
						return { actionType: $scope.currentAction.id, argument: $scope.selectedFolder.path, isDefaultAction: false };
				case 5:
				case 6:
					return { actionType: $scope.currentAction.id, argument: $scope.textInput, isDefaultAction: false };
				case 7:
				case 2:
					var addressListArg = $scope.addressList.reduce(function(res, address) {
						if (address.value)
							res.push(address.value);
						return res;
					}, []);
					return {
						actionType: $scope.currentAction.id,
						argument: addressListArg.join(';'),
						isDefaultAction: false,
						boolOption1: $scope.keepRecipients,
						boolOption2: $scope.deleteOriginal
					};
				case 9:
					return { actionType: $scope.currentAction.id, argument: $scope.prioritySelection, isDefaultAction: false };
			}
		};

		function onTextInputChanged(newValue, oldValue) {
			if (newValue === oldValue) return;
			if ($scope.currentAction && $scope.currentAction.id === 2) {
				if (validateMail(newValue)) {
					$scope.isValid = true;
				}
			}
		};
		function addEmail(userFormElement, idx) {
			if (!userFormElement) return;

			userFormElement.$validate();
			if (userFormElement.$invalid || !userFormElement.$viewValue) {
				return;
			} 
			if (!$scope.addressList.some(email => !email.value)) {
				$scope.addressList.push({ value: "" });
			}
		}
		async function save(userFormX) {
			var userForm = $scope.userForm;
			if (userForm && userForm.$invalid) {
				return;
			}

			if ($scope.currentAction.id === 7) {
				if ($scope.addressList.some(email => email.value === claimsService.getEmailAddress() && $scope.type === "user")) {
					$scope.isValid = false;
					errorHandling.report($translate.instant("CONTENT_FILTERING_FORWARD_MESSAGE_CANNOT_CONTAIN_OWN_EMAIL"));
					return;
				}
				userForm["email0"].$setValidity("required", $scope.addressList.some(email => email.value));
				if (userForm.$invalid) return;
			}

			if ($scope.isValid) {
				var saveAction = $scope.buildSaveAction();

				if (saveAction.actionType == 7) {
					//if (Object.keys(coreDataDomainSettings.domainPermissions).length == 0)
					//	await coreDataDomainSettings.loadDomainPermissions();

					var res = await $http.post('~/api/v1/settings/forwardable-address/' + $scope.type, JSON.stringify({ emailAddresses: $scope.addressList.map(email => email.value) }));
					if (!res.data.success) {
						if (res.data.message == "DOMAIN") {
							toaster.pop("error", $translate.instant("AUTOMATED_FORWARDING_DISABLED"));
						} else if (res.data.message == "USER") {
							toaster.pop("error", $translate.instant("AUTOMATED_FORWARDING_DISABLED"));
						} else {
							toaster.pop("error", $translate.instant("FORWARDS_TO_X_NOT_ALLOWED", { forwardingAddress: res.data.message }));
						}

						$scope.$applyAsync();
						return;
					}
				}

				$mdDialog.hide({ saveAction: saveAction, oldType: $scope.oldAction.actionType, deleteAction: false });
			}
		}

		function deleteThis() {
			$mdDialog.hide({ actionType: $scope.currentAction.id, deleteAction: true });
		}

		function cancel() {
			$mdDialog.cancel();
		}

		function emailDelete(index, form) {
			form.$setDirty();
			$scope.addressList.splice(index, 1);
			form["email0"].$setValidity("required", $scope.addressList.some(email => email.value));
		}

		$scope.onFolderChanged = function (newVal) {
			$scope.selectedFolder = JSON.parse(JSON.stringify(newVal));
			$scope.selectedFolderText = newVal.translatedName != undefined ? $filter('folderTranslate')(newVal.translatedName.replaceAll("\u00A0", '')) : newVal.name.replaceAll("\u00A0", '');
		}
	}

})();
