(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('messageCalendarInvite', {
            templateUrl: "app/email/message-view-components/message-calendar-invite.component.html",
            controller: messageCalendarInvite,
            bindings: {
                message: '<',
                meetingInfo: '<',
                viewMode: '<',
                onAcceptInvite: '&',
                onAcceptProposal: '&',
                onTentativeAcceptInvite: '&',
                onOpenProposeChangesModal: '&',
                onDeclineInvite: '&',
                onCheckAvailability: '&',
                onShowInviteDetails: '&',
                onDeleteInvite: '&',
                onIframeLoaded: '&',
            },
        });

    /* @ngInject */
    function messageCalendarInvite($timeout, $translate) {
        var vm = this;
        vm.resizeTries = 0;

        // Functions
        vm.acceptInvite = acceptInvite;
        vm.tentativeAcceptInvite = tentativeAcceptInvite
        vm.acceptProposal = acceptProposal;
        vm.declineInvite = declineInvite;
        vm.checkAvailability = checkAvailability;
        vm.showInviteDetails = showInviteDetails;
        vm.deleteInvite = deleteInvite;
        vm.openProposeChangesModal = openProposeChangesModal;
        /////////////////

        vm.$onChanges = function(changesObj) {
	        refreshDescription();

        }

        vm.$onInit = function() {
	        //refreshDescription();

        }
        function refreshDescription() {

	        if (vm.meetingInfo) {
		        vm.isSentInvite =
			        !(vm.meetingInfo.isReply || vm.meetingInfo.isCancel || vm.meetingInfo.isCounter) &&
			        vm.meetingInfo.isOrganizer;
		        vm.resizeTries = 0;
		        loadInviteIframe(vm.message);
	        }
        }

        function acceptInvite($event) {
            vm.onAcceptInvite($event, vm.meetingInfo);
        }

        function tentativeAcceptInvite($event) {
            vm.onTentativeAcceptInvite($event, vm.meetingInfo);
        }

        function acceptProposal($event) {
            vm.onAcceptProposal($event, vm.meetingInfo);
        }

        function declineInvite($event) {
            vm.onDeclineInvite($event, vm.meetingInfo);
        }

        function checkAvailability($event) {
            vm.onCheckAvailability($event, vm.meetingInfo);
        }

        function showInviteDetails($event) {
            vm.onShowInviteDetails($event, vm.meetingInfo);
        }

        function deleteInvite($event) {
            vm.onDeleteInvite($event, vm.meetingInfo);
        }

        function openProposeChangesModal($event) {
            vm.onOpenProposeChangesModal($event, vm.meetingInfo);
        }
        
        function loadInviteIframe() {
           
            const iframe = document.getElementById("meetingDescriptionFrame");

            if (iframe && iframe.contentWindow && iframe.contentWindow.setHTML) {
                // Set height to 0 so resizeIframe can get content heights less than 150px;
                $(iframe).css("height", "0");

                vm.message.translations = vm.message.translations || {};
                if (!vm.message.translations.MESSAGE_HAS_NO_TEXT_CONTENT)
                    vm.message.translations.MESSAGE_HAS_NO_TEXT_CONTENT = $translate.instant("MESSAGE_HAS_NO_TEXT_CONTENT");
                if (!vm.message.translations.MESSAGE_HAS_NO_HTML_CONTENT)
                    vm.message.translations.MESSAGE_HAS_NO_HTML_CONTENT = $translate.instant("MESSAGE_HAS_NO_HTML_CONTENT");

                iframe.contentWindow.setHTML(vm.message, "invite");
                var endSize = iframe.contentWindow.setHTML(vm.message, "invite");
                // wait for the content to load and get content height. Can't use the load event because
                // it tends to fire before we get to this function.
                const jqueryFrameDoc = $(iframe.contentDocument || iframe.contentWindow.document);
                $(iframe).ready(function() {
	                $timeout(onDocLoaded, 50);
                });
                // need to make sure the images are loaded too
                function onDocLoaded() {
	                if (!areImagesLoaded(jqueryFrameDoc)) {
		                $timeout(onDocLoaded, 50);
		                return;
	                }
	                const docHeight = jqueryFrameDoc.outerHeight();
	                $(iframe).css("height", `${docHeight || endSize}px`);
	                vm.onIframeLoaded();
                }

	            function areImagesLoaded(iframeDoc) {
		            const imgs = iframeDoc.find("img[src]:not(empty)");
		            if (imgs.length === 0)
			            return true;

		            for (let i = 0; i < imgs.length; i++) {
			            if (!imgs[i].complete || imgs[i].naturalHeight === 0)
				            return false;
		            }
		            return true;
	            }

            } else {
	            if (vm.resizeTries < 10) {
		            vm.resizeTries++;
		            $timeout(function() { loadInviteIframe(); }, 100);
	            }
            }

        }
    }
})();
